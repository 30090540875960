import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { userPropTypes } from 'helpers/propTypes';

import {
  getLocalStorage,
} from 'helpers/storage';
import Input from 'components/commons/Input';
import Button from 'components/commons/Button';
import ModalConfirmCart from 'components/commons/ConfirmCart';

import './PickupForm.scss';

const validate = (values) => {
  const errors = {};
  if (!values.first_name_retire) {
    errors.first_name_retire = 'Nombre inválido';
  }
  if (!values.last_name_retire) {
    errors.last_name_retire = 'Apellido inválido';
  }
  if (!values.id_card_retire || !/^[0-9]*$/i.test(values.id_card_retire)) {
    errors.id_card_retire = 'DNI inválido';
  }
  if (!values.email_retire || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email_retire)) {
    errors.email_retire = 'Email inválido';
  }
  if (!values.phone_retire || !/^(54)?[1-9]\d{9}$/i.test(values.phone_retire)) {
    errors.phone_retire = 'Número inválido (Ej: 541123456789)';
  }
  /*
  if (!values.comments) {
    errors.comments = 'Comentario inválido';
  }
  */
  return errors;
};

const PickupForm = (props) => {
  const {
    handleSubmit, initialize, onSubmit, submitting, user, couponText, setCouponText,
    onSubmitCoupon, coupon, emailComplete, setEmailComplete, validationEmailCoupon, setEmailCoupon,
    onConfirm, isModalOpen, setModalOpen, onDeleteCart,
  } = props;

  useEffect(() => {
    if (user) {
      initialize({
        first_name_retire: user.first_name,
        last_name_retire: user.last_name,
        email_retire: user.email,
        phone_retire: user.phone,
      });
      setEmailComplete(true);
      setEmailCoupon(user.email);
    } else {
      initialize(getLocalStorage('craco-info-pickup'));
      const formdata = getLocalStorage('craco-info-pickup');
      if (formdata) {
        setEmailComplete(true);
        setEmailCoupon(formdata.email_retire);
      }
    }
    // eslint-disable-next-line
  }, [user, setEmailComplete, setEmailCoupon]);

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSubmitCoupon();
    }
  };

  return (
    <div className="container" data-testid="pickup-form">
      <div className="row">
        <div className="form-container">
          <h4>Completá tus datos</h4>
          <form className="redux-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <Field
                component={Input}
                formGroupClassName="col-sm-4"
                label="Nombre  *"
                name="first_name_retire"
                inputClassName="inpute-search"
              />
              <Field
                formGroupClassName="col-sm-4"
                component={Input}
                label="Apellido  *"
                name="last_name_retire"
                inputClassName="inpute-search"
              />
              <Field
                formGroupClassName="col-sm-4"
                component={Input}
                label="DNI *"
                name="id_card_retire"
                type="number"
                inputClassName="inpute-search"
              />
            </div>
            <div className="row">
              <Field
                formGroupClassName="col-sm-6"
                component={Input}
                label="Email  *"
                disabled={!!user}
                name="email_retire"
                onChange={(event) => validationEmailCoupon(event.target.value)}
                type="email"
                inputClassName="inpute-search"
              />
              <Field
                formGroupClassName="col-sm-6"
                component={Input}
                label="Celular / WhatsApp  *"
                name="phone_retire"
                type="number"
                inputClassName="inpute-search"
              />
            </div>
            <Field
              component={Input}
              label="Comentarios"
              isTextArea
              name="comments"
            />
            <Field
              component={Input}
              label="Desea recibir novedades"
              name="suscribe"
              type="checkbox"
            />
            <div>
              <Field
                component={Input}
                label="Aplicar Cupón"
                name="coupon"
                disabled={!!coupon || !emailComplete}
                value={couponText}
                onChange={(event) => setCouponText(event.target.value)}
                onKeyPress={onKeyPress}
              />
              <Button
                buttonClass={!coupon ? 'btn-search-set' : 'btn-search-reset'}
                disabled={!emailComplete}
                text={!coupon ? ' Aplicar Cupón ' : <i className="fa fa-trash-o" aria-hidden="true" />}
                type="button"
                onClick={onSubmitCoupon}
              />
            </div>
            <Button text="Finalizar pedido" type="button" onClick={handleSubmit(onSubmit)} buttonClass="btn-block" disabled={submitting} />
            <div className="save-info">
              <Field
                name="save_data"
                label=""
                component={Input}
                type="checkbox"
                value="save_data"
              />
              <span>Guardar información para proximos pedidos</span>
            </div>
          </form>
        </div>
      </div>
      <ModalConfirmCart
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={onConfirm}
        onDeleteCart={onDeleteCart}
      />
    </div>
  );
};

PickupForm.defaultProps = {
  user: null,
  coupon: null,
  couponText: '',
  emailComplete: false,
};

PickupForm.propTypes = {
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
  }),
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  user: userPropTypes,
  couponText: PropTypes.string,
  setCouponText: PropTypes.func.isRequired,
  emailComplete: PropTypes.bool,
  setEmailComplete: PropTypes.func.isRequired,
  setEmailCoupon: PropTypes.func.isRequired,
  validationEmailCoupon: PropTypes.func.isRequired,
  onSubmitCoupon: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  onDeleteCart: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'pickup',
  validate,
})(PickupForm);
