import React from 'react';
import PropTypes from 'prop-types';

import Logo from 'components/main/Header/Logo';

import './Loading.scss';

const Loading = (props) => {
  const { message } = props;
  return (
    <div className="loading">
      <div className="logo-loading logo-fade">
        {' '}
        <Logo />
      </div>
      {message && <span className="loading-text">{message}</span>}
    </div>
  );
};

Loading.defaultProps = {
  message: null,
};

Loading.propTypes = {
  message: PropTypes.string,
};

export default Loading;
