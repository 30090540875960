import PropTypes from 'prop-types';

export const userPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string,
  address: PropTypes.string,
  number: PropTypes.string,
  departament: PropTypes.string,
  locality: PropTypes.string,
  province: PropTypes.string,
});

export const categoryPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
});

export const categoriesArrayPropTypes = PropTypes.arrayOf(categoryPropTypes);

export const productPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  categories: categoriesArrayPropTypes.isRequired,
  stock: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  sale: PropTypes.number.isRequired,
  image1: PropTypes.string.isRequired,
  image2: PropTypes.string,
  image3: PropTypes.string,
  image4: PropTypes.string,
  image5: PropTypes.string,
  image6: PropTypes.string,
});

export const productsArrayPropTypes = PropTypes.arrayOf(productPropTypes);

export const cartItemPropTypes = PropTypes.shape({
  item: productPropTypes,
  itemId: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
});

export const cartItemsArrayPropTypes = PropTypes.arrayOf(cartItemPropTypes);

export const sliderPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
});

export const imagesPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
});

export const pdfPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  pdf: PropTypes.string.isRequired,
});
export const imagesArrayPropTypes = PropTypes.arrayOf(imagesPropTypes);

export const slidersArrayPropTypes = PropTypes.arrayOf(sliderPropTypes);

export const whatsappPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  number: PropTypes.string.isRequired,
});

export const orderPropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  price: PropTypes.number,
  quantity: PropTypes.number,
  stock: PropTypes.number,
});

export const ordersArrayPropTypes = PropTypes.arrayOf(orderPropTypes);

export const purchaseHistoryPropTypes = PropTypes.shape({
  orderId: PropTypes.number,
  date: PropTypes.string,
  paymentMethod: PropTypes.string,
  state: PropTypes.string,
  total: PropTypes.number,
  products: ordersArrayPropTypes,
});

export const purchaseHistoryArrayPropTypes = PropTypes.arrayOf(purchaseHistoryPropTypes);
