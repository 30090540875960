import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from 'components/commons/Modal';
import VerificationEmail from 'components/commons/VerificationPassword';

const ResetPassVerificationContainer = (props) => {
  const {
    loading, onClose, validacionEnvio, msg,
  } = props;
  return (
    <Modal loading={loading} onClose={onClose} title="NOTIFICACIÓN">
      <VerificationEmail
        loading={loading}
        validacionEnvio={validacionEnvio}
        msg={msg}
        onClose={onClose}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  loading: state.user.loading,
  validacionEnvio: state.user.verifyMail,
  msg: state.user.msgVerifyPass,
});

ResetPassVerificationContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  validacionEnvio: PropTypes.bool.isRequired,
  msg: PropTypes.string.isRequired,

};

export default connect(mapStateToProps)(ResetPassVerificationContainer);
