import * as appConstants from 'redux/constants/app.constants';
import * as userConstants from 'redux/constants/user.constants';
import * as cartConstants from 'redux/constants/cart.constants';
import * as favouriteConstants from 'redux/constants/favourite.constants';
import { saveUserCookies, removeUserCookies } from 'helpers/cookies';
import {
  getLocalStorage, setLocalStorage, removeLocalStorage, clearLocalStorage,
} from 'helpers/storage';
import { updateCart } from 'helpers/cart';
import { updateFavourites } from 'helpers/favourite';

const dispatchCartChanged = (store, items) => {
  const { dispatch } = store;
  setLocalStorage('craco-cart', items);
  dispatch({
    type: cartConstants.CART_UPDATE_ITEMS_SUCCEEDED,
    items,
  });
};

const dispatchFavouritesChanged = (store, items) => {
  const { dispatch } = store;
  setLocalStorage('craco-favourites', items);
  dispatch({
    type: favouriteConstants.FAVOURITE_UPDATE_SUCCEEDED,
    items,
  });
};

const updateCartItems = (store, action) => {
  const { cart } = store.getState();
  const items = updateCart(cart.items, action.itemId, action.quantity,
    action.descriptionId, action.message);
  dispatchCartChanged(store, items);
};

const updateFavouriteItems = (store, action) => {
  const { favourite } = store.getState();
  const items = updateFavourites(favourite.items, action.itemId, action.favourite);
  dispatchFavouritesChanged(store, items);
};

const initializeCart = (store) => {
  const items = getLocalStorage('craco-cart') || [];
  dispatchCartChanged(store, items);
};

const initializeFavourites = (store) => {
  const items = getLocalStorage('craco-favourites') || [];
  dispatchFavouritesChanged(store, items);
};

const storageMiddleware = (store) => (next) => (action) => {
  const { data, type } = action;
  switch (type) {
    case appConstants.APP_INITIALIZE_REQUESTED:
      initializeCart(store);
      initializeFavourites(store);
      break;
    case userConstants.USER_LOGIN_SUCCEEDED:
      saveUserCookies(data.token);
      break;
    case cartConstants.CART_UPDATE_ITEMS_REQUESTED:
      updateCartItems(store, action);
      break;
    case favouriteConstants.FAVOURITE_UPDATE_REQUESTED:
      updateFavouriteItems(store, action);
      break;
    case cartConstants.CART_CLEAR_ITEMS_SUCCEEDED:
    case cartConstants.CART_CHECKOUT_SUCCEEDED:
      removeLocalStorage('craco-cart');
      break;
    case userConstants.USER_LOGOUT_SUCCEEDED:
      clearLocalStorage();
      removeUserCookies();
      break;
    default:
      break;
  }
  return next(action);
};

export default storageMiddleware;
