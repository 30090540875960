export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const USER_LOGIN_REQUESTED = 'USER_LOGIN_REQUESTED';
export const USER_LOGIN_SUCCEEDED = 'USER_LOGIN_SUCCEEDED';

export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';
export const USER_LOGOUT_REQUESTED = 'USER_LOGOUT_REQUESTED';
export const USER_LOGOUT_SUCCEEDED = 'USER_LOGOUT_SUCCEEDED';

export const USER_PROFILE_FAILED = 'USER_PROFILE_FAILED';
export const USER_PROFILE_REQUESTED = 'USER_PROFILE_REQUESTED';
export const USER_PROFILE_SUCCEEDED = 'USER_PROFILE_SUCCEEDED';

export const USER_REGISTER_FAILED = 'USER_REGISTER_FAILED';
export const USER_REGISTER_REQUESTED = 'USER_REGISTER_REQUESTED';
export const USER_REGISTER_SUCCEEDED = 'USER_REGISTER_SUCCEEDED';

export const USER_RESET_PASSWORD_REQUESTED = 'USER_RESET_PASSWORD_REQUESTED';
export const USER_RESET_PASSWORD_SUCCEEDED = 'USER_RESET_PASSWORD_SUCCEEDED';
export const USER_RESET_PASSWORD_FAILED = 'USER_RESET_PASSWORD_FAILED';

export const USER_CONTACT_REQUESTED = 'USER_CONTACT_REQUESTED';
export const USER_CONTACT_SUCCEEDED = 'USER_CONTACT_SUCCEEDED';
export const USER_CONTACT_FAILED = 'USER_CONTACT_FAILED';

export const USER_UPDATE_PROFILE_REQUESTED = 'USER_UPDATE_PROFILE_REQUESTED';
export const USER_UPDATE_PROFILE_SUCCEEDED = 'USER_UPDATE_PROFILE_SUCCEEDED';
export const USER_UPDATE_PROFILE_FAILED = 'USER_UPDATE_PROFILE_FAILED';

export const USER_CHANGE_PASSWORD_REQUESTED = 'USER_CHANGE_PASSWORD_REQUESTED';
export const USER_CHANGE_PASSWORD_SUCCEEDED = 'USER_CHANGE_PASSWORD_SUCCEEDED';
export const USER_CHANGE_PASSWORD_FAILED = 'USER_CHANGE_PASSWORD_FAILED';

export const USER_CHANGE_PASSWORD_EMAIL_REQUESTED = 'USER_CHANGE_PASSWORD_EMAIL_REQUESTED';
export const USER_CHANGE_PASSWORD_EMAIL_SUCCEEDED = 'USER_CHANGE_PASSWORD_EMAIL_SUCCEEDED';
export const USER_CHANGE_PASSWORD_EMAIL_FAILED = 'USER_CHANGE_PASSWORD_EMAIL_FAILED';

export const USER_GET_PURCHASE_HISTORY_REQUESTED = 'USER_GET_PURCHASE_HISTORY_REQUESTED';
export const USER_GET_PURCHASE_HISTORY_SUCCEEDED = 'USER_GET_PURCHASE_HISTORY_SUCCEEDED';
export const USER_GET_PURCHASE_HISTORY_FAILED = 'USER_GET_PURCHASE_HISTORY_FAILED';

export const USER_GET_ORDER_PDF_REQUESTED = 'USER_GET_ORDER_PDF_REQUESTED';
export const USER_GET_ORDER_PDF_SUCCEEDED = 'USER_GET_ORDER_PDF_SUCCEEDED';
export const USER_GET_ORDER_PDF_FAILED = 'USER_GET_ORDER_PDF_FAILED';

export const USER_VERIFY_EMAIL_REQUESTED = 'USER_VERIFY_EMAIL_REQUESTED';
export const USER_VERIFY_EMAIL_SUCCEEDED = 'USER_VERIFY_EMAIL_SUCCEEDED';
export const USER_VERIFY_EMAIL_FAILED = 'USER_VERIFY_EMAIL_FAILED';
