import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Loading from 'components/commons/Loading';
import Input from 'components/commons/Input';
import Button from 'components/commons/Button';

import './RegisterForm.scss';

const validate = (values) => {
  const errors = {};
  if (!values.first_name) {
    errors.first_name = 'Nombre inválido';
  }
  if (!values.last_name) {
    errors.last_name = 'Apellido inválido';
  }
  if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Dirección de email inválida';
  }
  if (!values.password) {
    errors.password = 'Contraseña inválida';
  } else if (values.password.length < 8) {
    errors.password = 'Mínimo 8 caracteres';
  }
  if (!values.password_confirmation) {
    errors.password_confirmation = 'Contraseña inválida';
  } else if (values.password_confirmation !== values.password) {
    errors.password_confirmation = 'Las contraseñas no coinciden';
  }
  if (!values.email_confirmation || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email_confirmation)) {
    errors.email_confirmation = 'Dirección de email inválida';
  } else if (values.email_confirmation !== values.email) {
    errors.email_confirmation = 'Los email no coinciden';
  }
  if (!values.phone || !/^(54)?[1-9]\d{9}$/i.test(values.phone)) {
    errors.phone = 'Número inválido (Ej: 541123456789)';
  }
  /*
  if (!values.address) {
    errors.address = 'Dirección inválida';
  }
  if (!values.number || !/^[0-9]*$/i.test(values.number)) {
    errors.number = 'Número inválido';
  }
  if (!values.departament) {
    errors.departament = 'Departamento inválido';
  }
  */
  if (!values.locality) {
    errors.locality = 'Localidad inválida';
  }
  if (!values.province) {
    errors.province = 'Provincia inválida';
  }
  return errors;
};

const RegisterForm = (props) => {
  const {
    handleSubmit, loading, openLoginModal, onSubmit, submitting,
  } = props;

  return (
    <div className="container" data-testid="register-form">
      <div className="row">
        {loading && <Loading message="Cargando..." size={80} />}
        <div className={`form-container ${loading ? 'd-none' : ''}`}>
          <form className="redux-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <Field
                component={Input}
                formGroupClassName="col-sm-6"
                inputClassName="inpute-search"
                label="Nombre"
                name="first_name"
              />
              <Field
                component={Input}
                formGroupClassName="col-sm-6"
                inputClassName="inpute-search"
                label="Apellido"
                name="last_name"
              />
            </div>
            <div className="row">
              <Field
                component={Input}
                formGroupClassName="col-sm-6"
                inputClassName="inpute-search"
                label="Dni"
                name="dni"
              />
              <Field
                component={Input}
                formGroupClassName="col-sm-6"
                label="Celular"
                inputClassName="inpute-search"
                name="phone"
                type="number"
              />
            </div>
            <Field
              component={Input}
              label="Email"
              name="email"
              inputClassName="inpute-search"
            />
            <Field
              component={Input}
              label="Confirmar email"
              name="email_confirmation"
              inputClassName="inpute-search"
            />
            <Field
              component={Input}
              inputClassName="inpute-search"
              label="Contraseña"
              name="password"
              type="password"
            />
            <Field
              component={Input}
              inputClassName="inpute-search"
              label="Confirmar contraseña"
              name="password_confirmation"
              type="password"
            />
            {/*
            <div className="row">
              <Field
                component={Input}
                formGroupClassName="col-sm-6"
                inputClassName="inpute-search"
                label="Número"
                name="number"
                type="number"
              />
              <Field
                component={Input}
                formGroupClassName="col-sm-6"
                inputClassName="inpute-search"
                label="Departamento"
                name="departament"
              />
            </div>
            <div className="row">
              <Field
                component={Input}
                formGroupClassName="col-sm-6"
                inputClassName="inpute-search"
                label="Localidad"
                name="locality"
              />
              <Field
                component={Input}
                formGroupClassName="col-sm-6"
                inputClassName="inpute-search"
                label="Provincia"
                name="province"
              />
            </div>
            */}
            <Field
              component={Input}
              label="Desea recibir novedades"
              name="suscribe"
              type="checkbox"
            />
            <div className="submit-button-container">
              <Button text="Crear cuenta" type="submit" buttonClass="btn-block" disabled={submitting} />
            </div>
            <div className="small clearfix">
              <div className="form-extra">
                <span className="link-log-in">Ya tenés una cuenta?</span>
                <span
                  className="form-extra-link"
                  onClick={openLoginModal}
                  role="presentation"
                >
                  {' Inicia sesión'}
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'register',
  validate,
})(RegisterForm);
