import React, { useState, useEffect, useRef } from 'react';
import ItemsCarousel from 'react-items-carousel';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import { productsArrayPropTypes } from 'helpers/propTypes';
import ItemContainer from 'containers/ItemContainer';

import 'react-tooltip/dist/react-tooltip.css';

const CarrouselProducts = (props) => {
  const { products, active } = props;
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const productsRef = useRef([]);
  const activeItemIndexRef = useRef(0);
  const intervalRef = useRef(0);

  const responsiveCarrousel = () => {
    const width = window.innerWidth;
    if (width >= 1000) {
      return 5;
    } if (width >= 768) {
      return 4;
    } if (width >= 550) {
      return 3;
    } if (width >= 200) {
      return 2;
    }
    return 1;
  };

  const tick = () => {
    const index = (activeItemIndexRef.current || 0) + 1;
    setActiveItemIndex(index % (productsRef.current.length - responsiveCarrousel() + 1));
  };

  const stopCarrousel = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = 0;
    }
  };

  const startCarrousel = () => {
    if (!active && !intervalRef.current) {
      intervalRef.current = setInterval(tick, 2000);
    }
  };

  useEffect(() => {
    startCarrousel();
    return stopCarrousel;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    productsRef.current = products;
  }, [products]);

  useEffect(() => {
    activeItemIndexRef.current = activeItemIndex;
  }, [activeItemIndex]);

  useEffect(() => {
    if (active) {
      stopCarrousel();
    } else if (active === '') {
      startCarrousel();
    }
    // eslint-disable-next-line
  }, [active]);

  const renderItemCarousel = (item) => (
    <div className="col-product-carrousel" id={`item-${item.id}`} key={`item-${item.id}`}>
      <ItemContainer item={item} />
      {item.stock_product_descriptions.length > 0 && window.innerWidth > 500
        && (
        <div className="tooltip-item">
          <ReactTooltip
            className="tooltipBorder"
            anchorId={`item-${item.id}`}
            place="bottom"
            variant="light"
          >
            {
              item.stock_product_descriptions.map((description) => (
                description.stock > 0 && (
                <li className="float-xs-left-v2" key={`${item.id}-${description.stock_description_id}`}>
                  {
                    description.stock_descriptions.rgb ? (
                      <div className="input-color">
                        <span className="color" style={{ backgroundColor: description.stock_descriptions.rgb }} />
                      </div>
                    ) : (
                      <div className="input-name">
                        <span className="name">{description.stock_descriptions.name}</span>
                      </div>
                    )
                  }
                </li>
                )
              ))
            }
          </ReactTooltip>
        </div>
        )}
    </div>
  );

  return (
    <div
      className="carrousel-wrapper"
      onMouseEnter={stopCarrousel}
      onMouseLeave={startCarrousel}
    >
      <ItemsCarousel
        infiniteLoop
        gutter={20}
        activePosition="center"
        chevronWidth={60}
        chevronHeight={30}
        numberOfCards={responsiveCarrousel()}
        outsideChevron
        activeItemIndex={activeItemIndex}
        requestToChangeActive={setActiveItemIndex}
        rightChevron={(
          <div className="button-carrousel">
            {'>'}
          </div>
        )}
        leftChevron={(
          <div className="button-carrousel">
            {'<'}
          </div>
        )}
      >
        {products.map((item) => renderItemCarousel(item))}
      </ItemsCarousel>
    </div>
  );
};

CarrouselProducts.defaultProps = {
  active: null,
};

CarrouselProducts.propTypes = {
  products: productsArrayPropTypes.isRequired,
  active: PropTypes.string,
};

export default CarrouselProducts;
