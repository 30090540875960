import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { resetPassword } from 'redux/actions/user.actions';
import { openPasswordVerificationModal } from 'redux/actions/modal.actions';

import Modal from 'components/commons/Modal';
import ResetPasswordForm from 'components/forms/ResetPasswordForm';

const ResetPasswordContainer = (props) => {
  const {
    loading, onClose, resetPasswordAction, openPasswordVerificationModalAction,
  } = props;

  const sendForm = (formData) => {
    resetPasswordAction(formData);
    openPasswordVerificationModalAction();
  };

  return (
    <Modal loading={loading} onClose={onClose} title="Olvide mi contraseña" isForgotPassword>
      <ResetPasswordForm
        loading={loading}
        onSubmit={sendForm}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  loading: state.user.loading,
});

const mapDispatchToProps = (dispatch) => ({
  resetPasswordAction: bindActionCreators(resetPassword, dispatch),
  openPasswordVerificationModalAction: bindActionCreators(openPasswordVerificationModal, dispatch),
});

ResetPasswordContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  resetPasswordAction: PropTypes.func.isRequired,
  openPasswordVerificationModalAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer);
