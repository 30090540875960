import * as constants from 'redux/constants/modal.constants';

export const openModal = (modal, data = null) => ({
  type: constants.MODAL_OPEN,
  modal,
  data,
});

export const closeModal = () => ({
  type: constants.MODAL_CLOSE,
});

export const openProductModal = (item) => openModal(constants.MODAL_PRODUCT, item);

export const openLoginModal = () => openModal(constants.MODAL_LOGIN);

export const openRegisterModal = () => openModal(constants.MODAL_REGISTER);

export const openPasswordVerificationModal = () => openModal(constants.MODAL_PASSWORD_VERIFICATION);

export const openResetPasswordModal = () => openModal(constants.MODAL_RESET_PASSWORD);

export const openSearchModal = () => openModal(constants.MODAL_SEARCH);

export const openCartModal = () => openModal(constants.MODAL_CART);

export const openPurcharseHistoryModal = () => openModal(constants.MODAL_PURCHARSE_HISTORY);

export const openImageModal = () => openModal(constants.MODAL_IMAGE);
