/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { productPropTypes } from 'helpers/propTypes';
import QuantitySelector from 'components/commons/QuantitySelector';
import './Item.scss';
import heartEmpty from './assets/heartEmpty.svg';
import heartFull from './assets/heartFull.svg';

const Item = (props) => {
  const {
    item, onItemClick, onQuantityChange, quantity, updateFavouriteItems, favouriteItem,
  } = props;

  const createdDate = new Date(item.restock ? item.restock.created_at : item.created_at);
  const today = new Date();
  const isNewProduct = item.new
    ? Math.ceil((today - createdDate) / (1000 * 60 * 60 * 24)) <= 7
    : false;
  const dots = (word) => (word.length > 40 ? `${word.substring(0, 40)}...` : word);

  return (
    <div className="product-item" data-testid={`product-${item.id}`}>
      <div className="product">
        <div className="product-image" role="presentation">
          <div className="product-image-icons">
            <img src={item.image1} alt={item.name} onClick={onItemClick} role="presentation" />
            {item.sale ? <div className="price-off">{` ${Math.round(100 - (item.sale / item.price) * 100)}% OFF `}</div> : <></>}
            {isNewProduct ? <div className="new-product"> Nuevo </div> : <></>}
            <div className="favorito-heart" onClick={() => updateFavouriteItems(item.id, favouriteItem)} role="presentation">
              {favouriteItem ? (
                <img src={heartFull} className="image-heart-favourite-fill" alt="" />
              ) : (
                <div className="heart-display">
                  <img src={heartEmpty} className="image-heart-favourite-empty" alt="" />
                  <img src={heartFull} className="image-heart-favourite-fill" alt="" />
                </div>
              ) }
            </div>
          </div>
        </div>
        <div className="product-info ">
          <h6 className="product-name" onClick={onItemClick} role="presentation">
            <div className="product-title-name">
              {dots(item.name)}
            </div>
          </h6>
          {
              item.stock_product_descriptions.length > 0
              && (
              <div className="product-price">
                {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                {!!item.sale && <span data-testid="product-on-sale">{`$${item.price.toLocaleString('de-DE')}`}</span>}
              </div>
              )
            }
          {!item.stock_product_descriptions.length > 0
              && (
              <div className="product-price">
                {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                {!!item.sale && <span data-testid="product-on-sale">{`$${item.price.toLocaleString('de-DE')}`}</span>}
              </div>
              )}
          {!item.stock_product_descriptions.length > 0
            ? (
              item.stock > 0 ? (
                <div className="product-quantity-selector">
                  <QuantitySelector
                    limit={item.stock}
                    onQuantityChange={onQuantityChange}
                    quantity={quantity}
                  />
                </div>
              ) : (
                <span className="product-out-of-stock">Sin stock</span>
              )) : (
                <div className="product-quantity-selector">
                  {
                    !!item.stock_product_descriptions.length > 0
                    && (
                    <button type="button" className="product-button-details" onClick={onItemClick}> Ver detalle </button>
                    )
                  }
                </div>
            )}
        </div>
      </div>
    </div>
  );
};

Item.propTypes = {
  item: productPropTypes.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  updateFavouriteItems: PropTypes.func.isRequired,
  favouriteItem: PropTypes.bool.isRequired,
};

export default Item;
