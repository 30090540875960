import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from 'components/commons/Modal';
import { Carousel } from 'react-responsive-carousel';

import { imagesArrayPropTypes } from 'helpers/propTypes';

const ImagePopUpContainer = (props) => {
  const {
    loading, onClose, imagePopUp,
  } = props;

  return (
    <Modal isImage loading={loading} onClose={onClose}>
      {
        imagePopUp && (
          <div className="carrousel-popup">
            <Carousel
              autoPlay
              showStatus={false}
              showThumbs={false}
              swipeable={false}
              showIndicators={false}
            >
              {imagePopUp.map(({ id, image, text }) => (
                <div className="main-slider" key={id}>
                  <img className="main-slider-img" src={image} alt={text} />
                </div>
              ))}
            </Carousel>
          </div>
        )
      }
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  loading: state.user.loading,
  imagePopUp: state.app.imagePopUp,
});

ImagePopUpContainer.propTypes = {
  // imagePopUp: PropTypes.img.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  imagePopUp: imagesArrayPropTypes.isRequired,
};

export default connect(mapStateToProps)(ImagePopUpContainer);
