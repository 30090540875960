import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import history from 'helpers/history';
import { productPropTypes } from 'helpers/propTypes';
import { getFullUrl } from 'helpers/window';
import { sendSuccessNotification, sendErrorNotification } from 'helpers/notifications';
import { itemQuantitySelector, itemsQuantitySelector } from 'redux/selectors/cart.selector';
import { productImagesSelector } from 'redux/selectors/product.selector';
import { itemFavouriteSelector } from 'redux/selectors/favourite.selector';
import { updateCart } from 'redux/actions/cart.actions';
import { updateFavourite } from 'redux/actions/favourite.actions';
import Product from 'views/Product';

const ProductContainer = (props) => {
  const {
    cartItem, images, item, onClose, updateCartAction, cartItemDescriptions,
    updateFavouritesAction, favouriteItem,
  } = props;
  // removeLocalStorage('descriptionsCart')
  const quantity = cartItem ? cartItem.quantity : 0;
  const singleProductUrl = `/producto/${encodeURIComponent(item.code)}`;
  const singleProductFullUrl = getFullUrl(singleProductUrl);

  const onProductClick = () => {
    onClose();
    history.push(singleProductUrl);
  };

  const onProductCopyClick = () => {
    sendSuccessNotification('Link del producto copiado con éxito');
  };

  const onQuantityChange = (value, description, notification) => {
    if (description) {
      if (value >= 0 && value <= description.stock) {
        updateCartAction(item.id, value, description.stock_description_id,
          item.message, notification);
      }
    } else if (value >= 0 && value <= item.stock) {
      updateCartAction(item.id, value, null, item.message, notification);
    }
  };

  const updateFavouriteItems = (id, action) => {
    updateFavouritesAction(id, action);
    if (!action) {
      sendSuccessNotification('Se agregó a favoritos');
    } else {
      sendErrorNotification('Se eliminó de favoritos');
    }
  };

  return (
    <Product
      images={images}
      item={item}
      onProductClick={onProductClick}
      onProductCopyClick={onProductCopyClick}
      onQuantityChange={onQuantityChange}
      quantity={quantity}
      cartItemDescriptions={cartItemDescriptions}
      singleProductFullUrl={singleProductFullUrl}
      updateFavouriteItems={updateFavouriteItems}
      favouriteItem={favouriteItem}
    />
  );
};

const mapStateToProps = (state, props) => ({
  cartItem: itemQuantitySelector(state, props),
  images: productImagesSelector(state, props),
  cartItemDescriptions: itemsQuantitySelector(state, props),
  favouriteItem: itemFavouriteSelector(state, props),
});

const mapDispatchToProps = (dispatch) => ({
  updateCartAction: bindActionCreators(updateCart, dispatch),
  updateFavouritesAction: bindActionCreators(updateFavourite, dispatch),
});

ProductContainer.defaultProps = {
  cartItem: null,
  onClose: () => { },
};

ProductContainer.propTypes = {
  cartItemDescriptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  cartItem: PropTypes.shape({
    itemId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }),
  item: productPropTypes.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func,
  updateCartAction: PropTypes.func.isRequired,
  updateFavouritesAction: PropTypes.func.isRequired,
  favouriteItem: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
