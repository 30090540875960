import React from 'react';
import { useLocation  } from 'react-router-dom';
import { connect } from 'react-redux';
import NewPasswordForm from 'components/forms/ResetPasswordEmailForm';
import { resetPasswordMail } from '../redux/actions/user.actions';
import { bindActionCreators } from 'redux';

const ResetPassEmailContainer = (props) => {
    const {resetPasswordMailAction} = props;
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const id_email = searchParams.get('id');
    const token = searchParams.get('token');
    

    const handleNewPasswordSubmit = (values) => {
        const { password } = values;
        resetPasswordMailAction({id_email, token, password});
    };

    return (
        <NewPasswordForm onSubmit={handleNewPasswordSubmit} />
    );
};

const mapDispatchToProps = (dispatch) => ({
    resetPasswordMailAction: bindActionCreators(resetPasswordMail, dispatch),
  });


export default connect(null, mapDispatchToProps)(ResetPassEmailContainer);