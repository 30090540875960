import React from 'react';
import PropTypes from 'prop-types';

import { whatsappPropTypes } from 'helpers/propTypes';

import Breadcrumb from 'components/commons/Breadcrumb';

import './FrequentQuestions.scss';

const FrequentQuestions = (props) => {
  const { contact, whatsapp } = props;

  return (
    <div data-testid="faq-page">
      <div className="container">
        <div className="row">
          <div className="col clearfix">
            <Breadcrumb urls={['/preguntas-frecuentes']} names={['Preguntas frecuentes']} />
            <div className="questions-container">
              <div className="question">
                <h3>¿Tiene un negocio a la calle?</h3>
                <p>
                  Nuestras oficinas están ubicadas en Camargo 136,
                  Villa Crespo, CABA, y nuestro horario de atención es de
                  {' '}
                  lunes a viernes de 8 a 17hs.
                </p>
              </div>
              <div className="question">
                <h3>¿Me tengo que registrar para hacer una compra?</h3>
                <p>
                  No es necesario que te registres para hacer el pedido.
                  Aunque luego de realizarlo vas a tener que poner tus datos de contacto
                  para que podamos coordinar el pago y el envío.
                  {' '}
                </p>
              </div>
              <div className="question">
                <h3>¿Puedo comprar productos por menos del monto mínimo?</h3>
                <p>
                  Las compras son únicamente por mayor. Tenés un monto de compra mínima obligatorio.
                  SI querés comprar productos por un valor menor al monto mínimo, hacé clic en el
                  {' '}
                  <a
                    className="link-question"
                    target="_blank"
                    href="https://listado.mercadolibre.com.ar/_CustId_186746733"
                    rel="noopener noreferrer"
                  >
                    siguiente link
                    {' '}

                  </a>
                  {' '}
                  y serás redirigido a Mercado Libre para que puedas comprar lo que desees.
                  {' '}
                </p>
              </div>
              <div className="question">
                <h3>¿Cómo abono el pedido?</h3>
                <p>
                  Una vez que registres la compra, te contactarán por mail y/o
                  {' '}
                  teléfono para coordinar el pago.
                  El mismo podrá ser por transferencia bancaria, depósito o efectivo,
                  {' '}
                  según corresponda.
                  {' '}
                </p>
              </div>
              <div className="question">
                <h3>¿Qué pasa si dentro del pedido recibí algún producto roto?</h3>
                <p>
                  No te preocupes. Contamos con un equipo especializado en reclamos y devoluciones.
                  Nos vas a poder contactar en todo momento y te vamos a asesorar para solucionar el
                  problema y que quedes conforme con tu compra.
                  {' '}
                </p>
              </div>
              <div className="question">
                <h3>¿Puedo elegir el color de los productos?</h3>
                <p>
                  Antes de finalizar el pedido vas a poder realizar comentarios
                  {' '}
                  al lado de cada producto. Ahí vas a poder elegir el color.
                  {' '}
                  Si lo tenemos, te vamos a respetar el color que elegiste.
                  {' '}
                  En caso de no tener el seleccionado, te enviaremos el producto en
                  {' '}
                  un color similar o en su defecto, en otro.
                  {' '}
                </p>
              </div>
              <div className="question">
                <h3>¿Si tengo alguna otra duda, puedo contactarlos?</h3>
                <p>
                  Obvio que si!! Mandanos un Whatsapp al
                  {' '}
                  <a href={`https://wa.me/${whatsapp ? whatsapp.number : 5491130680017}`} className="link-question" style={{ border: 'none' }}>{whatsapp ? whatsapp.number : '+5491130680017'}</a>
                  {' '}
                  o un mail a
                  {' '}
                  <span className="link-question">
                    { contact ? contact.email : 'ventas@craco.com.ar'}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FrequentQuestions.defaultProps = {
  contact: null,
  whatsapp: null,
};

FrequentQuestions.propTypes = {
  contact: PropTypes.shape({
    number: PropTypes.string,
    email: PropTypes.string,
  }),
  whatsapp: whatsappPropTypes,
};

export default FrequentQuestions;
