import * as userConstants from 'redux/constants/user.constants';
import * as cartConstants from 'redux/constants/cart.constants';
import history from 'helpers/history';

const redirectMiddleware = () => (next) => (action) => {
  const { type, data } = action;
  switch (type) {
    case userConstants.USER_LOGOUT_SUCCEEDED:
      history.push('/home');
      break;
    case cartConstants.CART_CHECKOUT_SUCCEEDED:
      history.push(`/compra-exitosa/${data.orderId}`);
      break;
    case userConstants.USER_CHANGE_PASSWORD_EMAIL_SUCCEEDED:
      history.push(`/home`);
      break;
    default:
      break;
  }
  return next(action);
};

export default redirectMiddleware;
