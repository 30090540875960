import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { productPropTypes } from 'helpers/propTypes';
import { getSingle } from 'redux/actions/product.actions';

import ProductContainer from 'containers/ProductContainer';
import Loading from 'components/commons/Loading';

const ProductSingleContainer = (props) => {
  const {
    code, getSingleAction, item, loading,
  } = props;

  useEffect(() => {
    if (code) getSingleAction(code);
    return () => getSingleAction('');
  }, [code, getSingleAction]);

  return (
    <>
      <br />
      <br />
      {loading && <Loading />}
      {!loading && (
        !item ? (
          <center><h4>No se ha encontrado el producto</h4></center>
        ) : <ProductContainer item={item} />
      )}
      <br />
      <br />
    </>
  );
};

const mapStateToProps = (state, props) => ({
  code: props.match.params.product,
  item: state.product.single,
  loading: state.product.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getSingleAction: bindActionCreators(getSingle, dispatch),
});

ProductSingleContainer.defaultProps = {
  item: null,
};

ProductSingleContainer.propTypes = {
  code: PropTypes.string.isRequired,
  getSingleAction: PropTypes.func.isRequired,
  item: productPropTypes,
  loading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSingleContainer);
