import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { productsArrayPropTypes, slidersArrayPropTypes } from 'helpers/propTypes';
import { initializeHome } from 'redux/actions/app.actions';
import { openImageModal } from 'redux/actions/modal.actions';
import { MOST_SELLED, OFFERS } from 'views/Home/constants';

import Home from 'views/Home';

const HomeContainer = (props) => {
  const {
    active, categoriesHome, loading, minimumPurchase, mostSelled,
    newArrivals, offers, sliders, initializeHomeAction,
  } = props;
  const [items, setItems] = useState(mostSelled);
  const [currentSection, setCurrentSeccion] = useState(MOST_SELLED);

  useEffect(() => {
    if (!offers.length || !newArrivals.length || !mostSelled.length || !categoriesHome.length) {
      initializeHomeAction();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setItems(mostSelled);
  }, [mostSelled, offers]);

  const setNewSection = (newSection) => {
    switch (newSection) {
      case OFFERS.value:
        setCurrentSeccion(OFFERS);
        setItems(offers);
        break;
      default:
        setCurrentSeccion(MOST_SELLED);
        setItems(mostSelled);
        break;
    }
  };

  return (
    <Home
      active={active}
      categoriesHome={categoriesHome}
      loading={loading}
      minimumPurchase={minimumPurchase}
      currentSection={currentSection}
      sliders={sliders}
      newArrivals={newArrivals}
      onSectionClick={setNewSection}
      items={items}
    />
  );
};

const mapStateToProps = (state) => ({
  active: state.modal.active,
  categoriesHome: state.category.itemsHome,
  loading: state.product.loading,
  minimumPurchase: state.app.minimumPurchase,
  mostSelled: state.product.mostSelled,
  newArrivals: state.product.newArrivals,
  offers: state.product.offers,
  sliders: state.app.sliders,
});

const mapDispatchToProps = (dispatch) => ({
  initializeHomeAction: bindActionCreators(initializeHome, dispatch),
  openImagePopUpAction: bindActionCreators(openImageModal, dispatch),
});

HomeContainer.defaultProps = {
  active: null,
};

HomeContainer.propTypes = {
  active: PropTypes.string,
  categoriesHome: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  mostSelled: productsArrayPropTypes.isRequired,
  newArrivals: productsArrayPropTypes.isRequired,
  offers: productsArrayPropTypes.isRequired,
  initializeHomeAction: PropTypes.func.isRequired,
  // openImagePopUpAction: PropTypes.func.isRequired,
  sliders: slidersArrayPropTypes.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
