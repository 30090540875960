import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import { RECAPTCHA_SITE_KEY } from 'constants/configs';

const Captcha = (props) => {
  const {
    formGroupClassName, input, meta: { touched, error },
  } = props;

  return (
    <div className={`form-group ${formGroupClassName}`}>
      <ReCAPTCHA
        data-testid="captcha"
        onChange={input.onChange}
        sitekey={RECAPTCHA_SITE_KEY}
      />
      {touched && (error && <span className="help-block">{error}</span>)}
    </div>
  );
};

Captcha.defaultProps = {
  formGroupClassName: '',
};

Captcha.propTypes = {
  formGroupClassName: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default Captcha;
