import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';

import {
  categoriesArrayPropTypes, userPropTypes, productsArrayPropTypes, imagesArrayPropTypes,
  whatsappPropTypes,
} from 'helpers/propTypes';
import history from 'helpers/history';
import { logout } from 'redux/actions/user.actions';
import { getSearch } from 'redux/actions/product.actions';
import {
  openLoginModal, openRegisterModal, openSearchModal, openPurcharseHistoryModal, openImageModal,
} from 'redux/actions/modal.actions';
import { favouriteSizeSelector } from 'redux/selectors/favourite.selector';

import Menu from 'components/main/Header/Menu';
import TopNav from 'components/main/Header/TopNav';
import Navbar from 'components/main/Header/Navbar';
import Button from 'components/commons/Button';

const HeaderContainer = (props) => {
  const {
    categories, logoutAction, openLoginModalAction, openDownloadOrderModalAction,
    favouriteSize, openRegisterModalAction, openSearchModalAction, user, openImagePopUpAction,
    imagePopUp, whatsapp, contact, products, getSearchAction,
  } = props;
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchProduct, setSearchProduct] = useState('');
  const location = useLocation();

  useEffect(() => {
    setIsSearchActive(false);
    setSelectedProduct(null);
    setSearchProduct('');
    getSearchAction('');
  }, [getSearchAction, location]);

  useEffect(() => {
    if (imagePopUp[0]) openImagePopUpAction();
  }, [openImagePopUpAction, imagePopUp]);

  const removeAccents = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const dots = (word) => (word.length > 45 ? `${word.substring(0, 45)}...` : word);

  const handleInputChange = (_, value) => {
    setSearchProduct(value);
    if (value !== undefined) getSearchAction(removeAccents(value));
  };

  const onSubmit = () => {
    const search = removeAccents(searchProduct).trim().toLowerCase();
    history.push(`/busqueda/${encodeURIComponent(search)}`);
  };

  const productSelected = (_, product) => {
    if (product.id) {
      history.push(`/producto/${encodeURIComponent(product.code)}`);
      setSelectedProduct(product);
    } else {
      const search = removeAccents(product).trim().toLowerCase();
      history.push(`/busqueda/${encodeURIComponent(search)}`);
    }
  };

  const logoutModal = () => {
    Swal.fire({
      title: '¿Estás seguro de que deseas cerrar sesión?',
      // text: "¡Perderás todo lo seleccionado!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        logoutAction();
      }
    });
  };

  const renderItem = (item) => (
    <div
      className="search-item"
      data-testid={`search-item-${item.id}`}
      key={item.id}
      role="presentation"
    >
      <div className="row">
        <div className="col-md-4 search-item-image">
          <img src={item.image1} alt={item.name} />
        </div>
        <div className="col-md-8 search-item-info">
          <ul>
            <li className="d-flex flex-row text-auto">
              <span className="search-item-title">
                {dots(item.name)}
              </span>
            </li>
            <li className="text-auto">
              <span className="search-item-price">
                {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                {!!item.sale && <span>{`$${item.price.toLocaleString('de-DE')}`}</span>}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );

  const renderResponsiveMenu = () => (
    <>
      <div
        className={`fs-menu-overlay${isMenuActive ? ' pointer-active' : ''}`}
        role="presentation"
        onClick={() => setIsMenuActive(false)}
      />
      <div className={`hamburger-menu${isMenuActive ? ' active' : ''}`}>
        <div className="hamburger-menu-content">
          <Menu
            categories={categories}
            closeMenu={() => setIsMenuActive(false)}
            isMobile
            isUserLoggedIn={!!user}
            ulClassName="menu-top-nav"
            logout={logoutModal}
            openLoginModal={openLoginModalAction}
            openRegisterModal={openRegisterModalAction}
            favouriteSize={favouriteSize}
            contact={contact}
          />
        </div>
      </div>
    </>
  );

  const renderResponsiveSearch = () => (
    <>
      <div
        className={`fs-search-menu-overlay${isSearchActive ? ' search-pointer-active' : ''}`}
        role="presentation"
        onClick={() => setIsSearchActive(false)}
      />
      <div className={`search-menu${isSearchActive ? ' active' : ''}`}>
        <div className="search-menu-content">
          <div className="nabvar-searchContainer-mobile">
            <div className="autocomplete">
              <Autocomplete
                key="auto-complete-search"
                disablePortal
                id="combo-box-demo"
                // classes="nabvar-search"
                options={products}
                freeSolo
                filterOptions={(options) => options}
                onChange={(event, product) => productSelected(event, product)}
                value={selectedProduct}
                onInputChange={handleInputChange}
                inputValue={searchProduct}
                getOptionSelected={(option, product) => option.id === product.id}
                getOptionLabel={(option) => (typeof option === 'string' ? option : renderItem(option))}
                renderInput={(params) => (<TextField {...params} placeholder="¿Qué producto estás buscando?" />)}
              />
              {searchProduct.trim().length >= 3 && (
                <Button
                  buttonClass="nabvar-btn-search"
                  disabled={false}
                  onClick={onSubmit}
                  text={<i className="fa fa-search icon-orange" aria-hidden="true" />}
                  type="button"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <header className="header trans-300">
      <TopNav
        logout={logoutModal}
        openLoginModal={openLoginModalAction}
        openRegisterModal={openRegisterModalAction}
        user={user}
        productSelected={searchProduct}
        openDownloadOrder={openDownloadOrderModalAction}
        favouriteSize={favouriteSize}
        whatsapp={whatsapp}
        contact={contact}
      />
      <div data-testid="navbar">
        <Navbar
          categories={categories}
          closeMenu={() => setIsMenuActive(false)}
          isMenuActive={isMenuActive}
          isUserLoggedIn={!!user}
          openMenu={() => setIsMenuActive(true)}
          openSearch={() => setIsSearchActive(true)}
          closeSearch={() => setIsSearchActive(false)}
          isSearchActive={isSearchActive}
          openSearchModal={openSearchModalAction}
          products={products}
          filterOptions={(options) => options}
          setSelectedProduct={productSelected}
          selectedProduct={selectedProduct}
          onSearchProductChange={handleInputChange}
          searchProduct={searchProduct}
          onSubmit={onSubmit}
          renderItem={renderItem}
        />
        {renderResponsiveMenu()}
        {renderResponsiveSearch()}
      </div>
    </header>
  );
};

const mapStateToProps = (state) => ({
  favouriteSize: favouriteSizeSelector(state),
  categories: state.category.items,
  favourites: state.favourite.items,
  user: state.user.data,
  products: state.product.search,
  imagePopUp: state.app.imagePopUp,
  whatsapp: state.app.whatsapp,
  contact: state.app.contact,
});

const mapDispatchToProps = (dispatch) => ({
  logoutAction: bindActionCreators(logout, dispatch),
  openLoginModalAction: bindActionCreators(openLoginModal, dispatch),
  openRegisterModalAction: bindActionCreators(openRegisterModal, dispatch),
  openSearchModalAction: bindActionCreators(openSearchModal, dispatch),
  openDownloadOrderModalAction: bindActionCreators(openPurcharseHistoryModal, dispatch),
  openImagePopUpAction: bindActionCreators(openImageModal, dispatch),
  getSearchAction: bindActionCreators(getSearch, dispatch),
});

HeaderContainer.defaultProps = {
  user: null,
  whatsapp: null,
  contact: null,
};

HeaderContainer.propTypes = {
  categories: categoriesArrayPropTypes.isRequired,
  getSearchAction: PropTypes.func.isRequired,
  logoutAction: PropTypes.func.isRequired,
  openLoginModalAction: PropTypes.func.isRequired,
  openRegisterModalAction: PropTypes.func.isRequired,
  openSearchModalAction: PropTypes.func.isRequired,
  openDownloadOrderModalAction: PropTypes.func.isRequired,
  favouriteSize: PropTypes.number.isRequired,
  user: userPropTypes,
  products: productsArrayPropTypes.isRequired,
  openImagePopUpAction: PropTypes.func.isRequired,
  imagePopUp: imagesArrayPropTypes.isRequired,
  whatsapp: whatsappPropTypes,
  contact: PropTypes.shape({
    number: PropTypes.string,
    email: PropTypes.string,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
