import React from 'react';
import PropTypes from 'prop-types';

import Loading from 'components/commons/Loading';
import Button from 'components/commons/Button';

import './VerificationPassword.scss';

const VerificationPassword = (props) => {
  const {
    validacionEnvio, loading, msg, onClose,
  } = props;

  return (
    <div className="container" data-testid="register-form">
      <div className="row justify-content-center">
        {loading ? <Loading />
          : (
            <>
              {validacionEnvio
                ? <i className="fa fa-check-circle" style={{ fontSize: '100px' }} aria-hidden="true" />
                : <i className="fa fa-exclamation-triangle" style={{ fontSize: '100px' }} aria-hidden="true" />}
              <span style={{ margin: '20px 0px', fontSize: '16px' }}>{msg}</span>
              <Button text="Aceptar" onClick={onClose} />
            </>
          )}
      </div>
    </div>
  );
};

VerificationPassword.propTypes = {
  loading: PropTypes.bool.isRequired,
  validacionEnvio: PropTypes.bool.isRequired,
  msg: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default VerificationPassword;
