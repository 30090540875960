import * as constants from 'redux/constants/product.constants';

export const getAll = (table) => ({
  type: constants.PRODUCT_GET_ALL_REQUESTED,
  ...table,
});

export const getAllOffers = (table) => ({
  type: constants.PRODUCT_GET_ALL_OFFERS_REQUESTED,
  ...table,
});

export const getAllSearch = (table) => ({
  type: constants.PRODUCT_GET_ALL_SEARCH_REQUESTED,
  ...table,
});

export const getSearch = (value) => ({
  type: constants.PRODUCT_GET_SEARCH_REQUESTED,
  value,
});

export const getSingle = (value) => ({
  type: constants.PRODUCT_GET_SINGLE_REQUESTED,
  value,
});
