import * as constants from 'redux/constants/cart.constants';
import * as userConstants from 'redux/constants/user.constants';

const defaultState = {
  coupon: null,
  items: [],
  loading: false,
  notification: '',
  products: [],
};

const cartReducer = (state = defaultState, action) => {
  const { data, type } = action;
  switch (type) {
    case constants.CART_CHECKOUT_DELIVERY_REQUESTED:
    case constants.CART_CHECKOUT_PICKUP_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.CART_GET_PRODUCTS_REQUESTED:
      return {
        ...state,
        loading: action.value.length > 0,
      };
    case constants.CART_DELETE_COUPON_REQUESTED:
      return {
        ...state,
        coupon: null,
      };
    case constants.CART_DELETE_NOTIFICATION_REQUESTED:
      return {
        ...state,
        notification: '',
      };
    case constants.CART_UPDATE_ITEMS_REQUESTED:
      return {
        ...state,
        notification: action.notification,
      };
    case constants.CART_GET_PRODUCTS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        products: data.items,
      };
    case constants.CART_SUBMIT_COUPON_SUCCEEDED:
      return {
        ...state,
        coupon: action.data.coupon,
      };
    case constants.CART_UPDATE_ITEMS_SUCCEEDED:
      return {
        ...state,
        items: action.items,
      };
    case constants.CART_CHECKOUT_FAILED:
      return {
        ...state,
        loading: false,
      };
    case constants.CART_GET_PRODUCTS_FAILED:
      return {
        ...state,
        loading: false,
        products: [],
      };
    case constants.CART_SUBMIT_COUPON_FAILED:
      return {
        ...state,
        coupon: null,
      };
    case constants.CART_CLEAR_ITEMS_SUCCEEDED:
    case constants.CART_CHECKOUT_SUCCEEDED:
    case userConstants.USER_LOGOUT_SUCCEEDED:
      return defaultState;
    default:
      return state;
  }
};

export default cartReducer;
