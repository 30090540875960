import React, { useEffect, memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { userPropTypes } from 'helpers/propTypes';

import Input from 'components/commons/Input';
import Button from 'components/commons/Button';
import Select from 'components/commons/Select';
import ModalConfirmCart from 'components/commons/ConfirmCart';

import './DeliveryForm.scss';
import '../PaymentForm/PaymentForm.scss';
import { getProvinces } from 'services/user.services';
import {
  getLocalStorage,
} from 'helpers/storage';
import {
  DELIVERY_FREE, DELIVERY_PAY, DELIVERY_FREE_PAY, DELIVERY_PAY_INTERIOR,
} from '../PaymentForm/constants';

const validate = (values) => {
  const errors = {};
  if (!values.first_name) {
    errors.first_name = 'Nombre inválido';
  }
  if (!values.last_name) {
    errors.last_name = 'Apellido inválido';
  }
  if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Dirección de email inválida';
  }
  if (!values.phone || !/^(54)?[1-9]\d{9}$/i.test(values.phone)) {
    errors.phone = 'Número inválido (Ej: 541123456789)';
  }
  if (!values.delivery_method) {
    errors.delivery_method = 'Debe elegir un método de envío';
  }
  if (!values.height) {
    errors.height = 'Debe elegir una altura';
  }
  if (!values.id_card || !/^[0-9]*$/i.test(values.id_card)) {
    errors.id_card = 'DNI inválido';
  }
  if (!values.address) {
    errors.address = 'Dirección inválida';
  }
  if (!values.postal_code) {
    errors.postal_code = 'Codigo postal inválido';
  }
  if (!values.province) {
    errors.province = 'Provincia inválida';
  }
  /*
  if (!values.number || !/^[0-9]*$/i.test(values.number)) {
    errors.number = 'Número inválido';
  }
  if (!values.departament) {
    errors.departament = 'Departamento inválido';
  }
  */
  if (!values.locality) {
    errors.locality = 'Localidad inválida';
  }
  if (!values.province) {
    errors.province = 'Provincia inválida';
  }
  return errors;
};

const DeliveryForm = (props) => {
  const {
    handleSubmit, initialize, onSubmit, submitting, user, couponText, setCouponText, onSubmitCoupon,
    coupon, emailComplete, setEmailComplete, validationEmailCoupon, setEmailCoupon, deliveryMethod,
    envioGratis, onConfirm, isModalOpen, setModalOpen, onDeleteCart,
  } = props;
  const [provinces, setProvinces] = useState([]);
  const options = provinces.map((province) => ({ label: province.name, value: province.name }));
  /* const options = provinces.map((province) => ({ label: province.name, value: province.name }));
  <Field name="province" component={Select} options={options} /> */
  const callProvinces = async () => {
    try {
      const responce = await getProvinces();
      setProvinces(responce.items);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    callProvinces();
    if (user) {
      initialize({ ...user });
      setEmailComplete(true);
      setEmailCoupon(user.email);
    } else {
      initialize(getLocalStorage('craco-info-delivery'));
      const formData = getLocalStorage('craco-info-delivery');
      if (formData) {
        setEmailComplete(true);
        setEmailCoupon(formData.email);
      }
    }
    // eslint-disable-next-line
  }, [user, setEmailComplete, setEmailCoupon]);

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSubmitCoupon();
    }
  };
  return (
    <div className="container" data-testid="delivery-form">
      <div className="row">
        <div className="form-container">
          <h4>Completá tus datos</h4>
          <form className="redux-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <Field
                component={Input}
                label="Nombre *"
                name="first_name"
                formGroupClassName="col-sm-4"
                inputClassName="inpute-search"
              />
              <Field
                component={Input}
                label="Apellido *"
                name="last_name"
                formGroupClassName="col-sm-4"
                inputClassName="inpute-search"
              />
              <Field
                formGroupClassName="col-sm-4"
                component={Input}
                label="DNI *"
                name="id_card"
                type="number"
                inputClassName="inpute-search"
              />
            </div>
            <div className="row">
              <Field
                component={Input}
                disabled={!!user}
                label="Mail *"
                onChange={(event) => validationEmailCoupon(event.target.value)}
                name="email"
                formGroupClassName="col-sm-6"
                inputClassName="inpute-search"
              />
              <Field
                formGroupClassName="col-sm-6"
                component={Input}
                label="Celular / WhatsApp *"
                name="phone"
                type="number"
                inputClassName="inpute-search"
              />
            </div>
            <div className="row">
              <Field
                formGroupClassName="col-sm-4"
                component={Input}
                label="Dirección *"
                name="address"
                inputClassName="inpute-search"
              />
              <Field
                formGroupClassName="col-sm-4"
                component={Input}
                label="Altura *"
                name="height"
                type="number"
                inputClassName="inpute-search"
              />
              <Field
                component={Input}
                formGroupClassName="col-sm-4"
                label="Departamento"
                name="departament"
                inputClassName="inpute-search"
              />
            </div>
            <div className="row">
              <Field
                component={Input}
                formGroupClassName="col-sm-4"
                label="Codigo Postal *"
                name="postal_code"
                inputClassName="inpute-search"
              />
              <Field
                component={Input}
                label="Localidad *"
                name="locality"
                formGroupClassName="col-sm-4"
                inputClassName="inpute-search"
              />
              <Field name="province" component={Select} label="Provincias" options={options} formGroupClassName="col-sm-4" />
            </div>
            <div>
              <Field
                component={Input}
                label="Aplicar Cupón"
                name="coupon"
                disabled={!!coupon || !emailComplete}
                value={couponText}
                onChange={(event) => setCouponText(event.target.value)}
                onKeyPress={onKeyPress}
              />
              <Button
                buttonClass={!coupon ? 'btn-search-set' : 'btn-search-reset'}
                disabled={!emailComplete}
                text={!coupon ? ' Aplicar Cupón ' : <i className="fa fa-trash-o" aria-hidden="true" />}
                type="button"
                onClick={onSubmitCoupon}
              />
            </div>
            <Field
              component={Input}
              label="Desea recibir novedades"
              name="suscribe"
              type="checkbox"
            />
            <div className="metodos-envio">
              <h4>Elegí un método de envío</h4>
            </div>
            <div className="checkout-radio-container">
              <div className="checkout-radio">
                <Field
                  formGroupClassName={deliveryMethod === DELIVERY_FREE ? 'radio-active' : ''}
                  name="delivery_method"
                  label=""
                  component={Input}
                  type="radio"
                  value={DELIVERY_FREE}
                  renderRadioDiv={() => (
                    <div className="radio-button-info">
                      <div className="radio-button-text">
                        <span className="radio-span">Por flete a CABA </span>
                        <span className="radio-span-subtitle">
                          ¡ENVIO GRATIS SUPERANDO LOS $
                          {envioGratis}
                          !
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              <div className="checkout-radio">
                <Field
                  formGroupClassName={deliveryMethod === DELIVERY_FREE_PAY ? 'radio-active' : ''}
                  name="delivery_method"
                  label=""
                  component={Input}
                  type="radio"
                  value={DELIVERY_FREE_PAY}
                  renderRadioDiv={() => (
                    <div className="radio-button-info">
                      <div className="radio-button-text">
                        <span className="radio-span">Por flete a GBA</span>
                        <span className="radio-span-subtitle">
                          ¡ENVIO GRATIS SUPERANDO LOS $
                          {envioGratis}
                          !
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              <div className="checkout-radio">
                <Field
                  formGroupClassName={deliveryMethod === DELIVERY_PAY ? 'radio-active' : ''}
                  name="delivery_method"
                  label=""
                  component={Input}
                  type="radio"
                  value={DELIVERY_PAY}
                  renderRadioDiv={() => (
                    <div className="radio-button-info">
                      <div className="radio-button-text">
                        <span className="radio-span">Por expreso al interior</span>
                        <span className="radio-span-subtitle">¡A abonar contra entrega!</span>
                      </div>
                    </div>
                  )}
                />
              </div>
              <div className="checkout-radio">
                <Field
                  formGroupClassName={deliveryMethod === DELIVERY_PAY_INTERIOR ? 'radio-active' : ''}
                  name="delivery_method"
                  label=""
                  component={Input}
                  type="radio"
                  value={DELIVERY_PAY_INTERIOR}
                  renderRadioDiv={() => (
                    <div className="radio-button-info">
                      <div className="radio-button-text">
                        <span className="radio-span">Por OCA al interior</span>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
            <Button text="Continuar" type="button" onClick={handleSubmit(onSubmit)} buttonClass="button-submit btn-block" disabled={submitting} />
            <div className="save-info">
              <Field
                name="save_data"
                label=""
                component={Input}
                type="checkbox"
                value="save_data"
              />
              <span>Guardar información para proximos pedidos</span>
            </div>
          </form>
        </div>
      </div>
      <ModalConfirmCart
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={onConfirm}
        onDeleteCart={onDeleteCart}
      />
    </div>
  );
};

DeliveryForm.defaultProps = {
  user: null,
  coupon: null,
  couponText: '',
  emailComplete: false,
  deliveryMethod: null,
};

DeliveryForm.propTypes = {
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
  }),
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  user: userPropTypes,
  couponText: PropTypes.string,
  setCouponText: PropTypes.func.isRequired,
  emailComplete: PropTypes.bool,
  setEmailComplete: PropTypes.func.isRequired,
  setEmailCoupon: PropTypes.func.isRequired,
  validationEmailCoupon: PropTypes.func.isRequired,
  onSubmitCoupon: PropTypes.func.isRequired,
  deliveryMethod: PropTypes.string,
  envioGratis: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  onDeleteCart: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'delivery',
  validate,
})(memo(DeliveryForm));
