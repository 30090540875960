import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { login } from 'redux/actions/user.actions';
import { openRegisterModal, openResetPasswordModal } from 'redux/actions/modal.actions';

import Modal from 'components/commons/Modal';
import LoginForm from 'components/forms/LoginForm';

const LoginContainer = (props) => {
  const {
    loading, loginAction, onClose, openRegisterModalAction, openResetPasswordModalAction,
  } = props;

  return (
    <Modal loading={loading} onClose={onClose} title="INICIAR SESIÓN">
      <LoginForm
        loading={loading}
        openRegisterModal={openRegisterModalAction}
        openResetPasswordModal={openResetPasswordModalAction}
        onSubmit={loginAction}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  loading: state.user.loading,
});

const mapDispatchToProps = (dispatch) => ({
  loginAction: bindActionCreators(login, dispatch),
  openRegisterModalAction: bindActionCreators(openRegisterModal, dispatch),
  openResetPasswordModalAction: bindActionCreators(openResetPasswordModal, dispatch),
});

LoginContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  loginAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  openRegisterModalAction: PropTypes.func.isRequired,
  openResetPasswordModalAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
