import React from 'react';
import PropTypes from 'prop-types';
import { purchaseHistoryArrayPropTypes } from 'helpers/propTypes';
import Loading from 'components/commons/Loading';
import CustomTable from 'components/commons/CustomTable';
import Modal from 'components/commons/Modal';
import PurchaseHistoryRow from './components/PurchaseHistoryRow';
import PurchaseHistoryMobileRow from './components/PurchaseHistoryMobileRow';

import useWindowSize from '../../hooks/useWindowSize';

const PurchaseHistory = (props) => {
  const {
    loading, onRepeatAllProductsClick, onRepeatProductClick, purchaseHistory,
    onDownloadOrderPDF, isModalPurcharseHisotry, onClose,
  } = props;
  const sizeWindow = useWindowSize();
  const purcharseSize = purchaseHistory.length > 0;

  const renderRow = (purchase) => (
    <PurchaseHistoryRow
      key={`purchase-${purchase.orderId}`}
      onRepeatAllProductsClick={onRepeatAllProductsClick}
      onRepeatProductClick={onRepeatProductClick}
      purchase={purchase}
      onDownloadOrderPDF={onDownloadOrderPDF}
    />
  );
  const renderRowMobile = (purchase) => (
    <PurchaseHistoryMobileRow
      key={`purchase-${purchase.orderId}`}
      onRepeatAllProductsClick={onRepeatAllProductsClick}
      onRepeatProductClick={onRepeatProductClick}
      purchase={purchase}
      onDownloadOrderPDF={onDownloadOrderPDF}
    />
  );

  const renderWebPurchaseHistory = () => (
    <div style={{ marginTop: '10px', marginBottom: '20px' }}>
      <CustomTable
        renderRow={renderRow}
        rows={purchaseHistory}
        titles={['', 'Num de order', 'Fecha de compra', 'Cantidad de productos', 'Monto total', 'PDF', '']}
      />
    </div>
  );
  const renderMobilePurchaseHistory = () => (
    <div className="container">

      <div className="purchase-mobile-container">
        {purchaseHistory.map((purchase) => (
          renderRowMobile(purchase)
        ))}
      </div>

    </div>
  );

  const renderFullPurchaseHistory = () => (
    <>
      {loading && <Loading message="Cargando..." size={80} />}
      {(!loading && purcharseSize) && (
        sizeWindow.width <= 850 ? (renderMobilePurchaseHistory()) : (
          renderWebPurchaseHistory()
        )
      )}
      {(!loading && !purcharseSize) && (
        <div className="no-realizo">
          <span data-testid="empty-cart">
            No ha realizado compras
          </span>
        </div>
      )}
    </>
  );

  const renderModalPurchaseHistory = () => (
    <Modal isPurchase loading={loading} onClose={onClose} title="Mis compras">
      <div className="row">
        <div className="col clearfix">
          <div className="row">
            <div className="col">
              <div className="cart-items">
                <div className="cart-full-orders">
                  { renderFullPurchaseHistory() }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
  return isModalPurcharseHisotry ? renderModalPurchaseHistory() : (
    <div data-testid="page-cart">
      <div className="cart-title">
        <h6>Mis Compras</h6>
      </div>
      <div className="container">
        <div className="row">
          <div className="col clearfix">
            <div className="row">
              <div className="col">
                {renderFullPurchaseHistory()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
PurchaseHistory.defaultProps = {
  isModalPurcharseHisotry: false,
};

PurchaseHistory.propTypes = {
  loading: PropTypes.bool.isRequired,
  onRepeatAllProductsClick: PropTypes.func.isRequired,
  onRepeatProductClick: PropTypes.func.isRequired,
  onDownloadOrderPDF: PropTypes.func.isRequired,
  purchaseHistory: purchaseHistoryArrayPropTypes.isRequired,
  isModalPurcharseHisotry: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default PurchaseHistory;
