import { all } from 'redux-saga/effects';
import { watchApp } from './app.sagas';
import { watchCart } from './cart.sagas';
import { watchFavourite } from './favourite.sagas';
import { watchCategories } from './category.sagas';
import { watchProducts } from './product.sagas';
import { watchUsers } from './user.sagas';

export default function* rootSaga() {
  yield all([
    watchApp(),
    watchCart(),
    watchFavourite(),
    watchCategories(),
    watchProducts(),
    watchUsers(),
  ]);
}
