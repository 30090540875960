import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { downloadOrderPDF } from 'redux/actions/user.actions';

import { getOrderId } from 'redux/selectors/cart.selector';
import SuccessfulPurchase from '../views/SuccessfulPurchase';

const SuccessfulPurchaseContainer = (props) => {
  const { orderId, downloadOrderPDFAction, loading } = props;
  const onDownloadOrderPDF = () => {
    downloadOrderPDFAction(orderId);
  };
  return (
    <SuccessfulPurchase
      orderId={orderId}
      onDownloadOrderPDF={onDownloadOrderPDF}
      loading={loading}
    />
  );
};

const mapStateToProps = (state, props) => ({
  orderId: getOrderId(state, props),
  loading: state.user.loading,
});
const mapDispatchToProps = (dispatch) => ({
  downloadOrderPDFAction: bindActionCreators(downloadOrderPDF, dispatch),
});

SuccessfulPurchaseContainer.propTypes = {
  orderId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  downloadOrderPDFAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessfulPurchaseContainer);
