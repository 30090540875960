import React from 'react';
import PropTypes from 'prop-types';

import { cartItemsArrayPropTypes } from 'helpers/propTypes';

import Loading from 'components/commons/Loading';
import CartSummary from 'components/commons/CartSummary';

import './Checkout.scss';
import Button from 'components/commons/Button';
import history from 'helpers/history';

const Checkout = (props) => {
  const {
    cartSize, items, loading, minimumPurchase, renderForm, shippingText, subtotal,
    coupon, isDelivery, isPickUp,
  } = props;

  const pushRoute = (e, route) => {
    e.preventDefault();
    history.push(route);
  };
  const showCart = cartSize > 0;

  return (
    <div data-testid="checkout-page" className="checkout-page">
      <div className="container">
        <div className="row">
          <div className="col clearfix">
            <div className="row">
              {loading && <Loading message="Cargando..." size={80} />}
              {!loading && (
                showCart ? (
                  <>
                    <div className="col-md-7">
                      <div className="checkout-place-container">
                        <span>¿Dónde querés recibir el pedido?</span>
                        <div className="row checkout-buttons">
                          <Button text="Envío a domicilio" onClick={(e) => pushRoute(e, '/envio-a-domicilio')} buttonClass={isDelivery ? 'button-checkout-selected mg-bot' : 'button-checkout mg-bot'} />
                          <Button text="Retiro en Sucursal Craco" onClick={(e) => pushRoute(e, '/retiro-en-sucursal')} buttonClass={isPickUp ? 'button-checkout-selected' : 'button-checkout'} />
                        </div>
                      </div>
                      <div className="checkout-form">
                        {renderForm()}
                      </div>
                    </div>
                    <div className="col-md-5 checkout-cart-summary">
                      <CartSummary
                        items={items}
                        shippingText={shippingText}
                        subtotal={subtotal}
                        coupon={coupon}
                      />
                    </div>
                    <div className="col">
                      <h4 className="text-center minimum-purchase" data-testid="minimum-purchase">
                        {`* Compra mínima: $${minimumPurchase}`}
                      </h4>
                    </div>
                  </>
                ) : (
                  <div className="col">
                    <h2 className="text-center" data-testid="empty-cart">
                      El carrito está vacío
                    </h2>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Checkout.defaultProps = {
  coupon: null,
  isDelivery: false,
  isPickUp: false,
};

Checkout.propTypes = {
  cartSize: PropTypes.number.isRequired,
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
  }),
  items: cartItemsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  renderForm: PropTypes.func.isRequired,
  shippingText: PropTypes.string.isRequired,
  subtotal: PropTypes.number.isRequired,
  isDelivery: PropTypes.bool,
  isPickUp: PropTypes.bool,
};

export default Checkout;
