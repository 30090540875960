import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import * as constants from 'redux/constants/app.constants';
import {
  getMinimumPurchase, getAllSliders, getWhatsapp, getDiscount, getPopUpImage, getContact,
  getPdfTarifas, getEnvioGratis,
} from 'services/app.services';

export function* minimumPurchaseGet() {
  try {
    const data = yield call(getMinimumPurchase);
    yield put({ type: constants.APP_MINIMUM_PURCHASE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_MINIMUM_PURCHASE_FAILED, error });
  }
}

export function* envioGratisGet() {
  try {
    const data = yield call(getEnvioGratis);
    yield put({ type: constants.APP_ENVIO_GRATIS_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_ENVIO_GRATIS_FAILED, error });
  }
}

export function* discountGet() {
  try {
    const data = yield call(getDiscount);
    yield put({ type: constants.APP_DISCOUNT_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_DISCOUNT_FAILED, error });
  }
}

export function* slidersGetAll() {
  try {
    const data = yield call(getAllSliders);
    yield put({ type: constants.APP_SLIDERS_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_SLIDERS_GET_ALL_FAILED, error });
  }
}

export function* whatsappGet() {
  try {
    const data = yield call(getWhatsapp);
    yield put({ type: constants.APP_WHATSAPP_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_WHATSAPP_FAILED, error });
  }
}

export function* popUpImageGet() {
  try {
    const data = yield call(getPopUpImage);
    yield put({ type: constants.APP_IMAGE_POPUP_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_IMAGE_POPUP_FAILED, error });
  }
}

export function* tarifasPdfGet() {
  try {
    const data = yield call(getPdfTarifas);
    yield put({ type: constants.APP_TARIFAS_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_TARIFAS_FAILED, error });
  }
}

export function* contactGet() {
  try {
    const data = yield call(getContact);
    yield put({ type: constants.APP_CONTACT_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_CONTACT_FAILED, error });
  }
}

export function* watchApp() {
  yield all([
    takeLatest(constants.APP_INITIALIZE_REQUESTED, minimumPurchaseGet),
    takeLatest(constants.APP_INITIALIZE_REQUESTED, envioGratisGet),
    takeLatest(constants.APP_INITIALIZE_REQUESTED, slidersGetAll),
    takeLatest(constants.APP_INITIALIZE_REQUESTED, whatsappGet),
    takeLatest(constants.APP_INITIALIZE_REQUESTED, tarifasPdfGet),
    takeLatest(constants.APP_INITIALIZE_REQUESTED, popUpImageGet),
    takeLatest(constants.APP_INITIALIZE_REQUESTED, contactGet),
  ]);
}
