import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { purchaseHistoryArrayPropTypes } from 'helpers/propTypes';
import { sendSuccessNotification } from 'helpers/notifications';
import { purchaseHistorySelector } from 'redux/selectors/user.selector';
import { getPurchaseHistory, downloadOrderPDF } from 'redux/actions/user.actions';
import { updateCart } from 'redux/actions/cart.actions';

import PurchaseHistoryView from 'views/PurchaseHistory';

const PurchaseHistoryContainer = (props) => {
  const {
    getPurchaseHistoryAction, loading, minimumPurchase, purchaseHistory, updateCartAction,
    downloadOrderPDFAction, isModalPurcharse, onClose,
  } = props;

  useEffect(() => {
    getPurchaseHistoryAction();
  }, [getPurchaseHistoryAction]);

  const onRepeatProductClick = ({ id, quantity, stock }) => {
    if (quantity <= stock) {
      updateCartAction(id, quantity);
      sendSuccessNotification('Producto agregado al carrito con éxito');
    }
  };

  const onRepeatAllProductsClick = (purchase) => {
    purchase.products.forEach(({ id, quantity, stock }) => {
      if (quantity <= stock) {
        updateCartAction(id, quantity);
      }
    });
    sendSuccessNotification('Productos agregados al carrito con éxito');
  };

  const onDownloadOrderPDF = (orderId) => {
    downloadOrderPDFAction(orderId);
  };

  return (
    <PurchaseHistoryView
      loading={loading}
      minimumPurchase={minimumPurchase}
      onRepeatAllProductsClick={onRepeatAllProductsClick}
      onRepeatProductClick={onRepeatProductClick}
      purchaseHistory={purchaseHistory}
      onDownloadOrderPDF={onDownloadOrderPDF}
      isModalPurcharseHisotry={isModalPurcharse}
      onClose={onClose}
    />
  );
};

PurchaseHistoryContainer.defaultProps = {
  isModalPurcharse: false,
};

const mapStateToProps = (state) => ({
  loading: state.user.loading,
  minimumPurchase: state.app.minimumPurchase,
  purchaseHistory: purchaseHistorySelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  downloadOrderPDFAction: bindActionCreators(downloadOrderPDF, dispatch),
  getPurchaseHistoryAction: bindActionCreators(getPurchaseHistory, dispatch),
  updateCartAction: bindActionCreators(updateCart, dispatch),
});

PurchaseHistoryContainer.propTypes = {
  getPurchaseHistoryAction: PropTypes.func.isRequired,
  downloadOrderPDFAction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  purchaseHistory: purchaseHistoryArrayPropTypes.isRequired,
  updateCartAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isModalPurcharse: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseHistoryContainer);
