import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { orderPropTypes } from 'helpers/propTypes';

import {
  IconButton, Collapse,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Button from 'components/commons/Button';
import downloadImage from '../assets/download.svg';
import './PurchaseHistoryRow.scss';

const PurchaseHistoryMobileRow = (props) => {
  const {
    onRepeatAllProductsClick, onRepeatProductClick, purchase,
    onDownloadOrderPDF,
  } = props;
  const [open, setOpen] = useState(false);

  const KeyboardArrowIcon = open ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;
  const quantity = purchase.products.reduce((accumulator, object) => accumulator
   + object.quantity, 0);

  const collapseProduct = () => (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <h4> Productos </h4>
      {
        purchase.products.map((product) => (
          <>
            <div className="product-container-purchase">
              <span className="cart-item-title purchase-mobile-title">
                {product.name}
              </span>
              <div className="purchase-items-container">
                <div className="items-container-purchase">
                  <ul>
                    <li className="col">
                      cantidad:
                      <span className="cart-item-title">
                        {product.quantity}
                      </span>
                    </li>
                    <li className="col">
                      $ x U:
                      <span className="cart-item-price">
                        $
                        {product.price}
                      </span>
                    </li>
                    <li className="col">
                      $ total:
                      <span className="cart-item-price-total">
                        $
                        {Math.round(product.quantity * product.price * 100) / 100}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="button-purcharse-mobile">
                  <Button
                    disabled={product.quantity > product.stock}
                    onClick={() => onRepeatProductClick(product)}
                    text="Repetir producto"
                  />
                </div>
              </div>
            </div>
          </>
        ))
      }
    </Collapse>
  );
  const renderMobilePurchaseHistoryRow = () => (
    <>
      <ul>
        <li>
          <span className="title-purchase">
            #
            {purchase.orderId}
          </span>
        </li>
        <li>
          <span className="title-purchase">
            {purchase.date}
          </span>
        </li>
        <li>
          <span className="title-purchase" style={{ marginRight: '10px' }}>
            {quantity}
            {' '}
            productos
          </span>
          <span className="price-purchase">
            $
            {purchase.total}
          </span>
        </li>
        <li style={{ marginBottom: '10px' }}>
          <span className="download-purchase" onClick={() => onDownloadOrderPDF(purchase.orderId)} role="presentation">
            <img src={downloadImage} className="image-redes-footer" alt="" />
            Descargar detalle
          </span>
        </li>
        <li>
          <Button
            onClick={() => onRepeatAllProductsClick(purchase)}
            text="Repetir pedido"
          />
        </li>
        <li>
          <span>Ver productos</span>
          <IconButton
            aria-label="expand row"
            data-testid="icon-button"
            onClick={() => setOpen(!open)}
            size="small"
          >
            <KeyboardArrowIcon color="disabled" />
          </IconButton>
          {collapseProduct()}
        </li>
      </ul>
      <div className="separator" />
    </>
  );

  return renderMobilePurchaseHistoryRow();
};

PurchaseHistoryMobileRow.propTypes = {
  onRepeatAllProductsClick: PropTypes.func.isRequired,
  onRepeatProductClick: PropTypes.func.isRequired,
  onDownloadOrderPDF: PropTypes.func.isRequired,
  purchase: orderPropTypes.isRequired,
};

export default PurchaseHistoryMobileRow;
