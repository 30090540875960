import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

import './Select.scss';

const Select = (props) => {
  const {
    input, formGroupClassName, inputClassName, label, options, disabled, meta: { touched, error },
  } = props;

  return (
    <div className={`form-group ${formGroupClassName}`}>
      <ReactSelect
        {...input}
        options={options}
        disabled={disabled}
        placeholder={label}
        className={inputClassName}
        data-testid="select"
        onBlur={(event) => event.preventDefault()}
      />
      {touched && (error && <span className="help-block">{error}</span>)}
    </div>
  );
};

Select.defaultProps = {
  formGroupClassName: '',
  inputClassName: '',
  disabled: false,
};

Select.propTypes = {
  formGroupClassName: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }).isRequired,
  inputClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Select;
