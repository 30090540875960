import * as constants from 'redux/constants/favourite.constants';

export const getProducts = (value) => ({
  type: constants.FAVOURITE_GET_PRODUCTS_REQUESTED,
  value,
});

export const updateFavourite = (itemId, favourite) => ({
  type: constants.FAVOURITE_UPDATE_REQUESTED,
  itemId,
  favourite,
});
