import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { productPropTypes } from 'helpers/propTypes';
import { itemQuantitySelector, itemsQuantitySelector } from 'redux/selectors/cart.selector';
import { itemFavouriteSelector } from 'redux/selectors/favourite.selector';
import { openProductModal } from 'redux/actions/modal.actions';
import { updateCart } from 'redux/actions/cart.actions';
import { updateFavourite } from 'redux/actions/favourite.actions';
import { sendSuccessNotification, sendErrorNotification } from 'helpers/notifications';
import Item from 'components/commons/Item';

const ItemContainer = (props) => {
  const {
    cartItem, favouriteItem, item, openProductModalAction, updateCartAction,
    cartItemDescriptions, updateFavouritesAction,
  } = props;

  const quantity = cartItem ? cartItem.quantity : 0;
  const onQuantityChange = (value, description, notification) => {
    if (description) {
      if (value >= 0 && value <= description.stock) {
        updateCartAction(
          item.id, value, description.stock_description_id, item.message, notification,
        );
      }
    } else if (value >= 0 && value <= item.stock) {
      updateCartAction(item.id, value, null, item.message, notification);
    }
  };

  const updateFavouriteItems = (id, action) => {
    updateFavouritesAction(id, action);
    if (!action) {
      sendSuccessNotification('Se agregó a favoritos');
    } else {
      sendErrorNotification('Se eliminó de favoritos');
    }
  };
  return (
    <Item
      item={item}
      quantity={quantity}
      cartItemDescriptions={cartItemDescriptions}
      onItemClick={() => openProductModalAction(item)}
      onQuantityChange={onQuantityChange}
      updateFavouriteItems={updateFavouriteItems}
      favouriteItem={favouriteItem}
    />
  );
};

const mapStateToProps = (state, props) => ({
  cartItem: itemQuantitySelector(state, props),
  cartItemDescriptions: itemsQuantitySelector(state, props),
  favouriteItem: itemFavouriteSelector(state, props),
});

const mapDispatchToProps = (dispatch) => ({
  openProductModalAction: bindActionCreators(openProductModal, dispatch),
  updateCartAction: bindActionCreators(updateCart, dispatch),
  updateFavouritesAction: bindActionCreators(updateFavourite, dispatch),
});

ItemContainer.defaultProps = {
  cartItem: null,
};

ItemContainer.propTypes = {
  cartItemDescriptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  cartItem: PropTypes.shape({
    itemId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }),
  favouriteItem: PropTypes.bool.isRequired,
  item: productPropTypes.isRequired,
  openProductModalAction: PropTypes.func.isRequired,
  updateCartAction: PropTypes.func.isRequired,
  updateFavouritesAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemContainer);
