import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { userPropTypes } from 'helpers/propTypes';

import Avatar from '@material-ui/core/Avatar';
import Person from '@material-ui/icons/Person';

import Input from 'components/commons/Input';
import Button from 'components/commons/Button';

import './ProfileForm.scss';

const validate = (values) => {
  const errors = {};
  if (!values.first_name) {
    errors.first_name = 'Nombre inválido';
  }
  if (!values.last_name) {
    errors.last_name = 'Apellido inválido';
  }
  if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Dirección de email inválida';
  }
  if (!values.phone || !/^(54)?[1-9]\d{9}$/i.test(values.phone)) {
    errors.phone = 'Número inválido (Ej: 541123456789)';
  }
  if (!values.address) {
    errors.address = 'Dirección inválida';
  }
  if (!values.number || !/^[0-9]*$/i.test(values.number)) {
    errors.number = 'Número inválido';
  }
  /*
  if (!values.departament) {
    errors.departament = 'Departamento inválido';
  }
  */
  if (!values.locality) {
    errors.locality = 'Localidad inválida';
  }
  if (!values.province) {
    errors.province = 'Provincia inválida';
  }
  return errors;
};

const ProfileForm = (props) => {
  const {
    handleSubmit, initialize, onSubmit, submitting, user,
  } = props;

  useEffect(() => {
    initialize({ ...user });
  }, [initialize, user]);

  return (
    <div className="container" data-testid="profile-form">
      <div className="row">
        <div className="form-container">
          <Avatar className="avatar">
            <Person />
          </Avatar>
          <h4>Actualizar perfil</h4>
          <form className="redux-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <Field
                component={Input}
                formGroupClassName="col-sm-6"
                label="Nombre"
                name="first_name"
              />
              <Field
                component={Input}
                formGroupClassName="col-sm-6"
                label="Apellido"
                name="last_name"
              />
            </div>
            <Field
              component={Input}
              label="Email"
              name="email"
            />
            <Field
              component={Input}
              label="Celular / WhatsApp"
              name="phone"
              type="number"
            />
            <Field
              component={Input}
              label="Dirección"
              name="address"
            />
            <div className="row">
              <Field
                component={Input}
                formGroupClassName="col-sm-6"
                label="Número"
                name="number"
                type="number"
              />
              <Field
                component={Input}
                formGroupClassName="col-sm-6"
                label="Departamento"
                name="departament"
              />
            </div>
            <div className="row">
              <Field
                component={Input}
                formGroupClassName="col-sm-6"
                label="Localidad"
                name="locality"
              />
              <Field
                component={Input}
                formGroupClassName="col-sm-6"
                label="Provincia"
                name="province"
              />
            </div>
            <Button text="Enviar" type="submit" buttonClass="btn-block" disabled={submitting} />
          </form>
        </div>
      </div>
    </div>
  );
};

ProfileForm.defaultProps = {
  user: null,
};

ProfileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  user: userPropTypes,
};

export default reduxForm({
  form: 'profile',
  validate,
})(ProfileForm);
