import * as constants from 'redux/constants/favourite.constants';

const defaultState = {
  items: [],
  loading: false,
  notification: '',
  products: [],
};

const favouriteReducer = (state = defaultState, action) => {
  const { data, type } = action;
  switch (type) {
    case constants.FAVOURITE_GET_PRODUCTS_REQUESTED:
      return {
        ...state,
        loading: action.value.length > 0,
      };
    case constants.FAVOURITE_UPDATE_REQUESTED:
      return {
        ...state,
        notification: action.notification,
      };
    case constants.FAVOURITE_GET_PRODUCTS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        products: data.items,
      };
    case constants.FAVOURITE_UPDATE_SUCCEEDED:
      return {
        ...state,
        items: action.items,
      };
    case constants.FAVOURITE_GET_PRODUCTS_FAILED:
      return {
        ...state,
        loading: false,
        products: [],
      };
    default:
      return state;
  }
};

export default favouriteReducer;
