import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { deleteNotification } from 'redux/actions/cart.actions';
import { cartSizeSelector } from 'redux/selectors/cart.selector';
import { openCartModal } from 'redux/actions/modal.actions';
import useWindowSize from '../../../hooks/useWindowSize';

import cartImage from './assets/cart.svg';
import './Cart.scss';

const Cart = (props) => {
  const {
    cartSize, notification, deleteNotificationAction, openCartModalAction,
  } = props;
  const size = useWindowSize();

  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        deleteNotificationAction();
      }, 3000);
    }
  }, [notification, deleteNotificationAction]);

  return (
    <>
      {size.width <= 850 ? (
        <Link
          className="cartCircle"
          to="/carrito"
        >
          <img src={cartImage} className="cartCircle-image" alt="cart" />
          <span id="checkout-cirlce" className="checkout-cirlce">
            {cartSize}
          </span>
        </Link>
      ) : (
        <div className="cartCircle" onClick={openCartModalAction} role="presentation">
          <img src={cartImage} className="cartCircle-image" alt="cart" role="presentation" />
          <span id="checkout-cirlce" className="checkout-cirlce">
            {cartSize}
          </span>
        </div>
      )}
      <div className={`notification-cart${notification ? ' active' : ''}`}>
        <span className="notification-span">{notification}</span>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  cartSize: cartSizeSelector(state),
  notification: state.cart.notification,
});

const mapDispatchToProps = (dispatch) => ({
  deleteNotificationAction: bindActionCreators(deleteNotification, dispatch),
  openCartModalAction: bindActionCreators(openCartModal, dispatch),
});

Cart.propTypes = {
  cartSize: PropTypes.number.isRequired,
  notification: PropTypes.string.isRequired,
  deleteNotificationAction: PropTypes.func.isRequired,
  openCartModalAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
