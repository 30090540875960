import React from 'react';
import { connect } from 'react-redux';

import { whatsappPropTypes } from 'helpers/propTypes';

import whatsappImage from './assets/whatsapp.png';

import './Whatsapp.scss';

const Whatsapp = (props) => {
  const { whatsapp } = props;
  return whatsapp && (
  <a
    className="whatsapp"
    href={`https://wa.me/${whatsapp.number}`}
    target="_blank"
    rel="noopener noreferrer"
    data-testid="whatsapp"
  >
    <img src={whatsappImage} className="whatsapp-image" alt="whatsapp" />
  </a>
  );
};

const mapStateToProps = (state) => ({
  whatsapp: state.app.whatsapp,
});

Whatsapp.defaultProps = {
  whatsapp: null,
};

Whatsapp.propTypes = {
  whatsapp: whatsappPropTypes,
};

export default connect(mapStateToProps)(Whatsapp);
