import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { verifyEmail } from '../redux/actions/user.actions';
import { bindActionCreators } from 'redux';

const VerifyEmailContainer = (props) => {
    const {verifyEmailAction} = props
    const [message, setMessage] = useState('');
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('id');
        const hash = queryParams.get('hash');
        console.log(id,hash);
        if (id && hash) {
            verifyEmailAction({id:id, hash:hash});
            history.push('/');
        }
    }, [location, verifyEmailAction, history]);

    return (
        <div>
            <h1>Email Verification</h1>
            <p>{message}</p>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    verifyEmailAction: bindActionCreators(verifyEmail, dispatch),
  });

  export default connect(null,mapDispatchToProps)(VerifyEmailContainer);