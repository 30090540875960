import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { categoriesArrayPropTypes, whatsappPropTypes } from 'helpers/propTypes';
import PropTypes from 'prop-types';

import facebook from './assets/facebook.svg';
import instagram from './assets/instagram.svg';
import whatsappImage from './assets/whatsapp.svg';
import twitter from './assets/twitter.svg';

import map from './assets/map.svg';
import './Footer.scss';

const Footer = (props) => {
  const { categories, whatsapp, contact } = props;
  return (
    <footer className="footer" data-testid="footer">
      <div className="container">
        <div className="row footer-top">
          <div className="col-lg-4 col-md-12 footer-column">
            <a target="_blank" href="https://www.google.com/maps/place/Craco/@-34.6036707,-58.4373811,15z/data=!4m2!3m1!1s0x0:0x4dcf13d2cc0ec113?sa=X&ved=2ahUKEwihnoLOj539AhVpt5UCHZLhCCEQ_BJ6BAh5EAg" rel="noopener noreferrer">
              <img className="image-footer" src={map} alt="" />
            </a>
          </div>
          <div className="col-lg-3 col-md-12 footer-column">
            <span className="footer-title">Cont&aacute;ctanos</span>
            <ul>
              <li className="subtitle-footer">Camargo 136</li>
              <li className="subtitle-footer">Villa Crespo (CABA)</li>
              <li className="subtitle-footer">
                { contact && contact.number }
              </li>
              <li className="subtitle-footer">
                <a href={`mailto:${contact ? contact.email : 'ventas@craco.com.ar'}`}>
                  { contact ? contact.email : 'ventas@craco.com.ar'}
                </a>
              </li>
            </ul>
            {' '}
          </div>
          <div className="col-lg-5 col-md-6 footer-column">
            <div className="info-footer">
              <div>
                <span className="footer-title">Accesos directos</span>
                <ul>
                  <li><Link className="subtitle-footer" to="/preguntas-frecuentes">Preguntas frecuentes</Link></li>
                  <li><Link className="subtitle-footer" to="/contacto">Contacto</Link></li>
                  <li><Link className="subtitle-footer" to="/contacto">Favorito</Link></li>
                </ul>
              </div>
              <div className="redes-footer">
                <span className="footer-title">Redes</span>
                <ul>
                  <li>
                    <a href="https://facebook.com/CRACO-Oficial-111468137389185/?business_id=170775904545104" target="_blank" rel="noopener noreferrer" className="subtitle-footer">
                      <img src={facebook} className="image-redes-footer" alt="facebook" />
                      {' '}
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/craco.oficial/" className="subtitle-footer">
                      <img src={instagram} className="image-redes-footer" alt="instagram" />
                      {' '}
                      Instagram
                    </a>
                  </li>
                  {
                    whatsapp && (
                    <li>
                      <a href={`https://wa.me/${whatsapp.number}`} className="subtitle-footer">
                        <img src={whatsappImage} className="image-redes-footer" alt="whatsapp" />
                        {' '}
                        Whatsapp
                      </a>
                    </li>
                    )
                  }
                  <li>
                    <span className="subtitle-footer">
                      <img src={twitter} className="image-redes-footer" alt="twitter" />
                      {' '}
                      Twitter
                    </span>

                  </li>
                </ul>
              </div>
            </div>
            <div>
              <span className="footer-title">Categorías</span>
              <div className="childs-categories">
                {
                  categories.map((category) => (
                    <div key={category.code} className="child-categories">
                      <Link to={`/productos/${category.code}`}>
                        {category.name}
                      </Link>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap d-md-flex py-4">
          <div className="copyright">
            CRACO Sitio Oficial &copy; 2023 Derechos reservados por
            {' '}
            <a href="https://jaypionba.com/" target="_blank" rel="noopener noreferrer">Jaypion</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => ({
  categories: state.category.items,
  whatsapp: state.app.whatsapp,
  contact: state.app.contact,
});

Footer.defaultProps = {
  whatsapp: null,
  contact: null,
};

Footer.propTypes = {
  categories: categoriesArrayPropTypes.isRequired,
  whatsapp: whatsappPropTypes,
  contact: PropTypes.shape({
    number: PropTypes.string,
    email: PropTypes.string,
  }),
};
export default connect(mapStateToProps)(Footer);
// export default Footer;
