import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { categoriesArrayPropTypes, productsArrayPropTypes } from 'helpers/propTypes';
import ScrollMenu from 'react-horizontal-scroll-menu';

import Menu from 'components/main/Header/Menu';
import Logo from 'components/main/Header/Logo';
import Button from 'components/commons/Button';
import { scrollTop } from 'helpers/window';
import useWindowSize from '../../../../hooks/useWindowSize';

import './Navbar.scss';

const Navbar = (props) => {
  const {
    categories, closeMenu, isMenuActive, isUserLoggedIn, openMenu,
    openSearch, closeSearch, isSearchActive, products, filterOptions, setSelectedProduct,
    selectedProduct, onSearchProductChange, searchProduct, onSubmit, renderItem, // selectProduct,
  } = props;
  const sizeWindow = useWindowSize();
  const [menuFijo, setMenuFijo] = useState(false);
  const menuRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuFijo(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <div className="main-nav-container">
      <div className="navbar-mobile">
        <div className={sizeWindow.width > 331 ? 'container' : ''}>
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar">
                <div className="navbar-content">
                  <div className="text-center">
                    <Logo />
                    <span className="top-nav-item-middle">Distribuidora Mayorista</span>
                  </div>
                  <Menu
                    categories={categories}
                    closeMenu={closeMenu}
                    isUserLoggedIn={isUserLoggedIn}
                    ulClassName="navbar-menu"
                  />
                  <ul className="navbar-user">
                    <li>
                      { !isSearchActive
                        ? (
                          <span className="icon-search-nav">
                            <i className="fa fa-search icon-orange" aria-hidden="true" onClick={openSearch} />
                          </span>
                        ) : (
                          <span className="icon-search-nav">
                            <i className="fa fa-search " aria-hidden="true" onClick={closeSearch} />
                          </span>
                        )}
                    </li>
                    <li>
                      { !isMenuActive
                        ? (
                          <div
                            className="hamburger-container"
                            role="presentation"
                            onClick={openMenu}
                          >
                            <i className="fa fa-bars" aria-hidden="true" />
                          </div>
                        )
                        : (
                          <div
                            className="hamburger-close"
                            role="presentation"
                            onClick={closeMenu}
                          >
                            <i className="fa fa-times" aria-hidden="true" />
                          </div>
                        )}

                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-categories" data-testid="navbar-categories">
        <div>
          <div className="row">
            <ul>
              <li>
                <NavLink to="/home">
                  Home
                </NavLink>
              </li>
              <li className="categories-navbar">
                <a className="cursor-pointer" href={() => false} ref={menuRef} onClick={() => setMenuFijo(!menuFijo)}>Categorías</a>
                <div className={menuFijo ? 'parent-fijo' : 'parent'}>
                  <ScrollMenu
                    alignCenter={false}
                    arrowDisabledClass="scroll-menu-arrow--disabled"
                    arrowLeft={<span className="arrow-prev">{' < '}</span>}
                    arrowRight={<span className="arrow-next">{' > '}</span>}
                    data={categories.map((category) => (
                      <div key={category.code} className="child">
                        <Link to={`/productos/${category.code}`}>
                          {category.name}
                        </Link>
                      </div>
                    ))}
                    dragging={false}
                    hideArrows
                    hideSingleArrow
                    wheel={false}
                  />
                </div>
              </li>
              <li>
                <NavLink to="/productos" onClick={scrollTop}>
                  Productos
                </NavLink>
              </li>
              <li>
                <div className="nabvar-searchContainer">
                  <div className="autocomplete">
                    <Autocomplete
                      key="auto-complete-search"
                      // fullWidth
                      // disablePortal
                      id="combo-box-demo"
                      // classes="nabvar-search"
                      /* fullWidth
                      autoHighlight
                      disableClearable */
                      options={products}
                      filterOptions={filterOptions}
                      freeSolo
                      onChange={(event, product) => setSelectedProduct(event, product)}
                      value={selectedProduct}
                      onInputChange={onSearchProductChange}
                      inputValue={searchProduct}
                      getOptionSelected={(option, product) => option.id === product.id}
                      getOptionLabel={(option) => (typeof option === 'string' ? option : renderItem(option))}
                      renderInput={(params) => <TextField {...params} placeholder="¿Qué producto estás buscando?" />}
                    />
                    {searchProduct.length >= 3
                      && (
                      <Button
                        buttonClass="nabvar-btn-search"
                        disabled={false}
                        onClick={onSubmit}
                        text={<i className="fa fa-search icon-orange" aria-hidden="true" />}
                        type="button"
                      />
                      )}
                  </div>
                </div>
              </li>
              <li>
                <NavLink to="/preguntas-frecuentes">
                  Preguntas frecuentes
                </NavLink>
              </li>
              <li>
                <NavLink to="/contacto">
                  Contacto
                </NavLink>
              </li>
              {/* categories.map((category) => (
                <li key={category.id}>
                  <Link to={`/productos/${category.code}`}>
                    {category.name}
                  </Link>
                </li>
              )) */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

Navbar.defaultProps = {
  selectedProduct: null,
};

Navbar.propTypes = {
  categories: categoriesArrayPropTypes.isRequired,
  closeMenu: PropTypes.func.isRequired,
  isMenuActive: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  openMenu: PropTypes.func.isRequired,
  openSearch: PropTypes.func.isRequired,
  closeSearch: PropTypes.func.isRequired,
  isSearchActive: PropTypes.bool.isRequired,
  openSearchModal: PropTypes.func.isRequired,
  products: productsArrayPropTypes.isRequired,
  filterOptions: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setSelectedProduct: PropTypes.func.isRequired,
  onSearchProductChange: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  selectedProduct: PropTypes.shape(),
  searchProduct: PropTypes.string.isRequired,
};

export default Navbar;
