import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/commons/Button';
import Loading from 'components/commons/Loading';
import successImage from './assets/success.svg';
import './SuccessfulPurchase.scss';

const SuccessfulPurchase = (props) => {
  const { orderId, onDownloadOrderPDF, loading } = props;
  return (
    <div className="container" data-testid="successful-purchase-page">
      <div className="row successful-purchase flex-column justify-content-center align-items-center text-center">
        <img src={successImage} alt="successful-purchase" className="successful-purchase-check" />
        <br />
        <p className="successful-purchase-title">¡Tu pedido fue realizado con &eacute;xito!</p>
        <br />
        <p>
          <span>
            En pocos minutos se le estará enviando un mail a tu casilla de correo electr&oacute;nico
            {' '}
            con el detalle de tu pedido. Recordá verificar la carpeta de “SPAM” o
            “correo no deseado”.
          </span>
          <br />
          <br />
          <span>
            Tu número de pedido es #

            <span className="succesfull-orderId">{orderId}</span>
            {' '}
            ¡Es importante que lo tengas en cuenta!
          </span>
          <br />
          <br />
          <span>
            En breve nos estaremos comunicando con vos para coordinar forma de pago y env&iacute;o.
          </span>
        </p>
        <div className="account-item-menu">
          {loading && <Loading message="Cargando..." size={80} />}
          {!loading && (
          <>
            <div className="top-nav-item-menu">
              <Button buttonClass="button-come" text="Volver al inicio" href="/home" />
            </div>
            <div className="top-nav-item-menu">
              <Button
                onClick={() => onDownloadOrderPDF()}
                text={(
                  <span>
                    <i className="fa fa-download" aria-hidden="true" />
                    {' '}
                    Descargar detalle
                  </span>
                  )}
                buttonClass="button-reverse"
              />
            </div>
          </>
          )}
        </div>
      </div>
    </div>
  );
};

SuccessfulPurchase.propTypes = {
  orderId: PropTypes.string.isRequired,
  onDownloadOrderPDF: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
export default SuccessfulPurchase;
