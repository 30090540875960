import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { orderPropTypes } from 'helpers/propTypes';

import {
  TableRow, TableCell, IconButton, Collapse, Box, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import CustomTable from 'components/commons/CustomTable';
import Button from 'components/commons/Button';
import downloadImage from '../assets/download.svg';
import './PurchaseHistoryRow.scss';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: '1px solid rgba(255, 92, 0, 0.5)',
    },
  },
  root1: {
    '& > *': {
      borderBottom: 0,
    },
  },
});

const PurchaseHistoryRow = (props) => {
  const {
    onRepeatAllProductsClick, onRepeatProductClick, purchase,
    onDownloadOrderPDF,
  } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  const KeyboardArrowIcon = open ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;
  const quantity = purchase.products.reduce(
    (accumulator, object) => accumulator + object.quantity, 0,
  );

  return (
    <>
      <TableRow className={classes.root} data-testid="purchase-row">
        <TableCell padding="none">
          <IconButton
            aria-label="expand row"
            data-testid="icon-button"
            onClick={() => setOpen(!open)}
            size="small"
          >
            <KeyboardArrowIcon color="disabled" />
          </IconButton>
        </TableCell>
        <TableCell padding="none" align="center" size="small">
          <span className="title-purchase">
            #
            {purchase.orderId}
          </span>
        </TableCell>
        <TableCell padding="none" align="center">
          <span className="title-purchase">
            {purchase.date}
          </span>
        </TableCell>
        <TableCell padding="none" align="center">
          <span className="title-purchase">
            {quantity}
            {' '}
            productos
          </span>
        </TableCell>
        <TableCell padding="none" align="center">
          <span className="price-purchase">
            $
            {purchase.total}
          </span>
        </TableCell>
        <TableCell padding="none" align="center">
          <span className="download-purchase" onClick={() => onDownloadOrderPDF(purchase.orderId)} role="presentation">
            <img src={downloadImage} className="image-redes-footer" alt="" />
            Descargar detalle
          </span>
        </TableCell>
        <TableCell align="right">
          <Button
            onClick={() => onRepeatAllProductsClick(purchase)}
            text="Repetir pedido"
          />
        </TableCell>
      </TableRow>
      <TableRow className={classes.root1}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Productos
              </Typography>
              <CustomTable
                renderRow={(product) => (
                  <TableRow key={product.id}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>
                      $
                      {product.price}
                    </TableCell>
                    <TableCell>{product.quantity}</TableCell>
                    <TableCell>
                      $
                      {Math.round(product.quantity * product.price * 100) / 100}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        disabled={product.quantity > product.stock}
                        onClick={() => onRepeatProductClick(product)}
                        text="Repetir producto"
                      />
                    </TableCell>
                  </TableRow>
                )}
                rows={purchase.products}
                size="small"
                titles={['Producto', 'Precio unitario', 'Cantidad', 'Total', '']}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

PurchaseHistoryRow.propTypes = {
  onRepeatAllProductsClick: PropTypes.func.isRequired,
  onRepeatProductClick: PropTypes.func.isRequired,
  onDownloadOrderPDF: PropTypes.func.isRequired,
  purchase: orderPropTypes.isRequired,
};

export default PurchaseHistoryRow;
