/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import { categoriesArrayPropTypes } from 'helpers/propTypes';
import history from 'helpers/history';
import facebookImage from './assets/facebook.svg';
import whatsappImage from './assets/whatsapp.svg';
import tiktok from '../../../../views/Contact/assets/tiktok.png';
import instagramImage from './assets/instagram.svg';
import heartImage from './assets/heart.svg';
import './Menu.scss';

const Menu = (props) => {
  const {
    categories, closeMenu, isMobile, isUserLoggedIn, ulClassName,
    logout, openLoginModal, openRegisterModal, favouriteSize, contact,
  } = props;

  const onFavouriteClick = () => {
    history.push('/favoritos');
  };

  const renderCategoriesMobile = () => (
    <div className="products-menu-mobile" data-testid="nav-products-mobile">
      <div>
        <a className="text-menu" data-testid="nav-faq" href={() => false}>
          Categorías
        </a>
        <label htmlFor="products-menu-mobile-checkbox-0" className="products-menu-mobile-toggle">
          <i className="fa fa-angle-down" />
        </label>
      </div>
      <input type="checkbox" id="products-menu-mobile-checkbox-0" />
      <ul className="products-sub-menu-mobile">
        {categories.map((category) => (
          <li key={category.id}>
            <NavLink activeClassName="active" className="sub-categories-menu" onClick={closeMenu} to={`/productos/${category.code}`}>
              {category.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
  const renderUserLoggedOutButtons = () => (
    <>
      <li className="account-li" role="presentation" onClick={closeMenu}>
        <div className="top-nav-item-favourite-menu-container">
          <span className="top-nav-item-favourite-menu" onClick={onFavouriteClick} role="presentation">
            <img src={heartImage} className="image-heart-menu" alt="" />
            Favoritos (
            {favouriteSize}
            )
          </span>
        </div>
      </li>
      <li className="account-li" role="presentation">
        <div className="account-item-menu">
          <span className="top-nav-item-menu" role="presentation" onClick={openRegisterModal}>
            Creá tu cuenta
          </span>
          <span className="top-nav-item-menu" role="presentation" onClick={openLoginModal}>
            Iniciar sesi&oacute;n
          </span>
        </div>
      </li>
    </>
  );

  const renderUserLoggedInButtons = () => (
    <>
      <li className="account-li" role="presentation" onClick={closeMenu}>
        <NavLink to="/compras" className="compras-menu">
          Mis compras
        </NavLink>
      </li>
      <li className="account-li" role="presentation" onClick={closeMenu}>
        <div className="top-nav-item-favourite-menu-container-login">
          <span className="top-nav-item-favourite-menu" onClick={onFavouriteClick} role="presentation">
            <img src={heartImage} className="image-heart-menu" alt="" />
            Favoritos (
            {favouriteSize}
            )
          </span>
        </div>
      </li>
      <li className="account-li" role="presentation" onClick={closeMenu}>
        <div className="account-item-menu">
          <span className="top-nav-item-menu" role="presentation">
            <Link className="mi-cuenta-menu" to="/perfil">
              <i className="fa fa-user" aria-hidden="true" />
              {' '}
              Mi cuenta
            </Link>
          </span>
          <span className="top-nav-item-menu" role="presentation" onClick={logout}>
            Cerrar sesi&oacute;n
          </span>
        </div>
      </li>
    </>
  );

  return (
    <>
      <ul className={ulClassName}>
        {!isUserLoggedIn && renderUserLoggedOutButtons()}
        {isUserLoggedIn && renderUserLoggedInButtons()}
      </ul>
      <div className="titles-mobile-menu">
        <ul className={ulClassName} data-testid="menu">
          <li role="presentation" onClick={closeMenu}>
            <NavLink to="/home" activeClassName="active" data-testid="nav-home">
              Home
            </NavLink>
          </li>
          <li className="products-menu-link" role="presentation">
            {!isMobile && (
            <NavLink to="/productos" activeClassName="active" onClick={closeMenu} data-testid="nav-faq">
              Categorías
            </NavLink>
            )}
            {isMobile && renderCategoriesMobile()}
          </li>
          <li role="presentation" onClick={closeMenu}>
            <NavLink to="/productos" activeClassName="active" data-testid="nav-products">
              Productos
            </NavLink>
          </li>
          <li role="presentation" onClick={closeMenu}>
            <NavLink to="/preguntas-frecuentes" activeClassName="active" data-testid="nav-faq">
              Preguntas frecuentes
            </NavLink>
          </li>
          <li role="presentation" onClick={closeMenu}>
            <NavLink to="/contacto" activeClassName="active" data-testid="nav-contact">
              Contacto
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="redes-mobile">
        <ul className={ulClassName}>
          <li className="account-li" role="presentation">
            <div className="redes-menu-mobile">
              <div className="account-item-menu">
                <div className="icons-menu-mobile">
                  { contact && (<div className="image-social icon-contact"><a href={`https://wa.me/${contact.number}`} target="_blank" rel="noopener noreferrer"><img src={whatsappImage} alt="whatsapp" /></a></div>)}
                  <div className="image-social icon-contact"><a href="https://facebook.com/CRACO-Oficial-111468137389185/?business_id=170775904545104" target="_blank" rel="noopener noreferrer"><img src={facebookImage} alt="facebook" /></a></div>
                  <div className="image-social icon-contact"><a href="https://www.instagram.com/craco.oficial/" target="_blank" rel="noopener noreferrer"><img src={instagramImage} alt="instagram" /></a></div>
                  <div className="image-social icon-contact"><a href="https://vm.tiktok.com/ZMYHrNemm/" target="_blank" rel="noopener noreferrer"><img className="tiktok-contact" src={tiktok} alt="tiktok" /></a></div>
                </div>
              </div>
            </div>
          </li>
          <li className="account-li" role="presentation">
            <div className="redes-menu-mobile">
              <span className="redes-span-menu" role="presentation">
                { contact ? contact.number : 'Numero'}
              </span>
              <span className="redes-span-menu" role="presentation">
                { contact ? contact.email : 'ventas@craco.com.ar'}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

Menu.defaultProps = {
  isMobile: false,
  logout: null,
  openLoginModal: null,
  openRegisterModal: null,
  favouriteSize: 0,
  contact: null,
};

Menu.propTypes = {
  categories: categoriesArrayPropTypes.isRequired,
  closeMenu: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool.isRequired,
  ulClassName: PropTypes.string.isRequired,
  logout: PropTypes.func,
  openLoginModal: PropTypes.func,
  openRegisterModal: PropTypes.func,
  favouriteSize: PropTypes.number,
  contact: PropTypes.shape({
    number: PropTypes.string,
    email: PropTypes.string,
  }),
};

export default Menu;
