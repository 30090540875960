import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { whatsappPropTypes } from 'helpers/propTypes';

import Loading from 'components/commons/Loading';
import Input from 'components/commons/Input';
import Button from 'components/commons/Button';
import Captcha from 'components/commons/Captcha';
import facebookImage from './assets/facebook.svg';
import whatsappImage from './assets/whatsapp.svg';
import instagramImage from './assets/instagram.svg';
import tiktok from './assets/tiktok.png';
import './Contact.scss';

const validate = (values) => {
  const errors = {};
  if (!values.first_name) {
    errors.first_name = 'Nombre inválido';
  }
  if (!values.last_name) {
    errors.last_name = 'Apellido inválido';
  }
  /* istanbul ignore next */
  if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Email inválido';
  }
  if (!values.message) {
    errors.message = 'Mensaje inválido';
  }
  if (!values.captcha) {
    errors.captcha = 'Captcha inválido';
  }
  return errors;
};

const Contact = (props) => {
  const {
    handleSubmit, loading, onSubmit, submitting, whatsapp, contact,
  } = props;

  const renderContactForm = () => (
    <form className="redux-form-contact" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <Field
          component={Input}
          formGroupClassName="col-sm-6"
          inputClassName="input-search-contact"
          label="Nombre *"
          name="first_name"
        />
        <Field
          component={Input}
          formGroupClassName="col-sm-6"
          inputClassName="input-search-contact"
          label="Apellido *"
          name="last_name"
        />
      </div>
      <Field
        component={Input}
        name="email"
        inputClassName="input-search-contact"
        label="Correo electronico *"
        type="email"
      />
      <Field
        component={Input}
        isTextArea
        inputClassName="height-message"
        label="Dejanos tu mensaje *"
        name="message"
      />
      <Field name="captcha" component={Captcha} />
      <Button text="Enviar mensaje" type="submit" buttonClass="btn-block" disabled={submitting} />
    </form>
  );

  const renderLoading = () => (
    <div className="contact-loading">
      <Loading message="Cargando..." />
    </div>
  );

  return (
    <div data-testid="contact-page">
      <div id="google-map">
        <div className="map-container">
          <iframe
            id="map"
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.0194102983696!2d-58.4395697855523!3d-34.60367068045954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcca72800dcf95%3A0xbeab8341440412ec!2sCamargo%20136%2C%20C1414AHD%20CABA!5e0!3m2!1ses-419!2sar!4v1604706324192!5m2!1ses-419!2sar"
            allowFullScreen=""
            aria-hidden="false"
          />
        </div>
      </div>
      <div className="container">
        <div className="info-contact-title">
          <h2>Contactanos</h2>
        </div>
        <div className="info-contact">
          <div className="row">
            <div className="col-lg-7">
              <div className="get-in-touch-contents">
                {loading && renderLoading()}
                {!loading && renderContactForm()}
              </div>
            </div>
            <div className="col-lg-4 get-in-touch-col">
              <div className="contact-contents">
                <div>
                  <span>Dirección: </span>
                  <span>Camargo 136 (Villa Crespo)</span>
                </div>
                <div>
                  <span>Teléfono: </span>
                  <span>
                    { contact ? contact.number : '1161250017'}
                  </span>
                </div>
                <div>
                  <span>Email: </span>
                  <span>
                    <a href={`mailto:${contact ? contact.email : 'ventas@craco.com.ar'}`}>
                      { contact ? contact.email : 'ventas@craco.com.ar'}
                    </a>
                  </span>
                </div>
                <div>
                  <span>Horarios de atención: </span>
                  <span>
                    Lunes a VIernes de 8 a 17 Hs. Sábados y Domingos cerrado
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="icons-contact-container">
          {whatsapp && (<div className="icon-contact"><a href={`https://wa.me/${whatsapp.number}`} target="_blank" rel="noopener noreferrer"><img src={whatsappImage} alt="whatsapp" /></a></div>)}
          <div className="icon-contact"><a href="https://business.facebook.com/CRACO-Oficial-111468137389185/?business_id=170775904545104" target="_blank" rel="noopener noreferrer"><img src={facebookImage} alt="facebook" /></a></div>
          <div className="icon-contact"><a href="https://www.instagram.com/craco.oficial/" target="_blank" rel="noopener noreferrer"><img src={instagramImage} alt="instagram" /></a></div>
          <div className="icon-contact"><a href="https://vm.tiktok.com/ZMYHrNemm/" target="_blank" rel="noopener noreferrer"><img className="tiktok-contact" src={tiktok} alt="tiktok" /></a></div>
        </div>
      </div>
    </div>
  );
};

Contact.defaultProps = {
  whatsapp: null,
  contact: null,
};

Contact.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  whatsapp: whatsappPropTypes,
  contact: PropTypes.shape({
    number: PropTypes.string,
    email: PropTypes.string,
  }),
};

export default reduxForm({
  form: 'contact',
  validate,
})(Contact);
