import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import './Breadcrumb.scss';

const Breadcrumb = (props) => {
  const { urls, names } = props;

  return (
    <div className="breadcrumbs d-flex flex-row align-items-center" data-testid="breadcrumb">
      <ul>
        <li key="breadcrumb-home"><Link to="/home">Home</Link></li>
        {urls.map((url, i) => names[i] && (
          <li key={`breadcrumb-${names[i]}`}>
            <Link to={url}>
              <i className="fa fa-angle-right" aria-hidden="true" />
              {names[i]}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

Breadcrumb.propTypes = {
  urls: PropTypes.arrayOf(PropTypes.string).isRequired,
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Breadcrumb;
