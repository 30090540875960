import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Logo from 'components/main/Header/Logo';
import './ConfirmCart.scss';

const ConfirmCart = ({
  isOpen, onClose, onConfirm, onDeleteCart,
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-confirmCart-overlay">
      <div className="modal-confirmCart">
        <div className="modal-confirmCart-content">
          <Logo style={{ fontSize: '200px' }} />
          <br />
          <strong>
            Estás a punto de finalizar tu pedido.
            {' '}
            Un vendedor se contactará en las próximas 24/48hs hábiles con vos
            {' '}
            para coordinar el pago y el envío.
          </strong>
          <br />
          <br />
          <strong>¿FINALIZAR PEDIDO?</strong>
        </div>
        <div className="modal-confirmCart-actions">
          <button className="btn btn-confirm" onClick={onConfirm} type="button">
            Finalizar Pedido
          </button>
          <button className="btn btn-close" onClick={onClose} type="button">
            Volver al Carrito
          </button>
          <Link to="/" className="btn btn-empty" onClick={onDeleteCart}>ABANDONAR PEDIDO</Link>
        </div>
      </div>
    </div>
  );
};

ConfirmCart.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDeleteCart: PropTypes.func.isRequired,
};

export default ConfirmCart;
