export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';

export const MODAL_PRODUCT = 'MODAL_PRODUCT';
export const MODAL_LOGIN = 'MODAL_LOGIN';
export const MODAL_REGISTER = 'MODAL_REGISTER';
export const MODAL_IMAGE = 'MODAL_IMAGE';
export const MODAL_RESET_PASSWORD = 'MODAL_RESET_PASSWORD';
export const MODAL_SEARCH = 'MODAL_SEARCH';
export const MODAL_CART = 'MODAL_CART';
export const MODAL_PURCHARSE_HISTORY = 'MODAL_PURCHARSE_HISTORY';
export const MODAL_PASSWORD_VERIFICATION = 'MODAL_PASSWORD_VERIFICATION';
