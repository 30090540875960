import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logo from 'components/main/Header/Logo';
import history from 'helpers/history';
import { userPropTypes, whatsappPropTypes } from 'helpers/propTypes';
import heartImage from './assets/hart.svg';
import facebookImage from './assets/facebook-contact.svg';
import whatsappImage from './assets/whatsapp-contact.svg';
import instagramImage from './assets/instagram-contact.svg';

import './TopNav.scss';

const TopNav = (props) => {
  const {
    logout, openLoginModal, openRegisterModal, user, openDownloadOrder, favouriteSize,
    whatsapp, contact,
  } = props;

  const onFavouriteClick = () => {
    history.push('/favoritos');
  };

  const renderUserLoggedOutButtons = () => (
    <div className="col-md-5 top-nav-user-actions" data-testid="top-nav-user-actions">
      <span className="top-nav-item" onClick={openRegisterModal} role="presentation">
        Creá tu cuenta
      </span>
      <span className="top-nav-item" onClick={openLoginModal} role="presentation">
        Iniciar sesi&oacute;n
      </span>

      <span className="top-nav-item-favourite" onClick={onFavouriteClick} role="presentation">
        <img src={heartImage} className="image-heart" alt="" />
        Favoritos (
        {favouriteSize}
        )
      </span>
    </div>
  );

  const renderUserLoggedInButtons = () => (
    <div className="col-md-5 top-nav-user-actions" data-testid="top-nav-user-actions">
      <NavLink className="top-nav-item top-nav-border-right" to="/perfil">
        <i className="fa fa-user" aria-hidden="true" />
        {' '}
        Mi cuenta
      </NavLink>
      <span className="top-nav-item" role="presentation" onClick={logout}>
        Cerrar sesi&oacute;n
      </span>
      <span onClick={openDownloadOrder} role="presentation" className="top-nav-item">
        Mis compras
      </span>
      <span className="top-nav-item-favourite" onClick={onFavouriteClick} role="presentation">
        <img src={heartImage} className="image-heart" alt="" />
        Favoritos (
        {favouriteSize}
        )
      </span>
    </div>
  );

  return (
    <div className="top-nav" data-testid="topnav">
      <div className="container fill-height">
        <div className="row top-nav-content fill-height">
          <div className="col-md-5 top-nav-info">

            { whatsapp && (<a className="top-nav-item" href={`https://wa.me/${whatsapp.number}`} target="_blank" rel="noopener noreferrer"><img src={whatsappImage} className="image-social" alt="whatsapp" /></a>)}
            <a href="https://facebook.com/CRACO-Oficial-111468137389185/?business_id=170775904545104" target="_blank" rel="noopener noreferrer" className="top-nav-item"><img src={facebookImage} className="image-social" alt="facebook" /></a>
            <a href="https://www.instagram.com/craco.oficial/" className="top-nav-item top-nav-border-right"><img src={instagramImage} className="image-social" alt="instagram" /></a>
            <span className="top-nav-item top-nav-border-right">
              { contact ? contact.number : 'Numero'}
            </span>
            <a className="top-nav-item" href={`mailto:${contact ? contact.email : 'ventas@craco.com.ar'}`}>
              { contact ? contact.email : 'ventas@craco.com.ar'}
            </a>
          </div>
          <div className="col-md-2 top-nav-info text-center">
            <Logo />
            <span className="top-nav-item-middle">Distribuidora Mayorista</span>
          </div>
          {!user && renderUserLoggedOutButtons()}
          {user && renderUserLoggedInButtons()}
        </div>
      </div>
    </div>
  );
};

TopNav.defaultProps = {
  user: null,
  whatsapp: null,
  contact: null,
};

TopNav.propTypes = {
  logout: PropTypes.func.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  openRegisterModal: PropTypes.func.isRequired,
  openDownloadOrder: PropTypes.func.isRequired,
  favouriteSize: PropTypes.number.isRequired,
  user: userPropTypes,
  whatsapp: whatsappPropTypes,
  contact: PropTypes.shape({
    number: PropTypes.string,
    email: PropTypes.string,
  }),
};

export default TopNav;
