import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import CarrouselProducts from 'components/commons/CarrouselProducts';
import Slider from '@material-ui/core/Slider';
import Pagination from '@material-ui/lab/Pagination';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import Select from 'react-select';
import Breadcrumb from 'components/commons/Breadcrumb';

import {
  categoriesArrayPropTypes, categoryPropTypes, productsArrayPropTypes,
} from 'helpers/propTypes';

import Loading from 'components/commons/Loading';
import useWindowSize from '../../hooks/useWindowSize';

import './Products.scss';

const Products = (props) => {
  const {
    category, categories, handleInputRangeChange, handlePaginationChange,
    inputRangeValue, orderOptions, orderProducts, handleSelectSortedProducts, items,
    loading, maxPrice, name, page, renderItem, total, url, productsOffers,
    active, handleRangeChange,
  } = props;

  const sizeWindow = useWindowSize();
  const [categoriesAccordion, setCategoriesAccordion] = useState(false);
  const [priceAccordion, setPriceAccordion] = useState(false);

  const styles = {
    multiValue: (base) => ({
      ...base,
      borderRadius: '90%',
    }),
    control: (base) => ({
      ...base,
      fontSize: '14px',
    }),
    menu: (base) => ({
      ...base,
      fontSize: '14px',
    }),
  };

  const renderCategories = () => (
    <div className="sidebar-section">
      <div className="sidebar-title show-filter-categorys">
        <h5>Categorías</h5>
      </div>
      <ul className="sidebar-categories show-filter-categorys" data-testid="sidebar-categories">
        <li key="category-all">
          <NavLink exact to={url}>Todas</NavLink>
        </li>
        {categories.map((cat) => (
          <li key={`category-${cat.id}`}>
            <NavLink
              to={`${url}/${cat.code}`}
              activeClassName="active"
            >
              {cat.name}
            </NavLink>
          </li>
        ))}
      </ul>
      <Accordion className="show-filter-categorys-secondary" expanded={categoriesAccordion}>
        <AccordionSummary
          expandIcon={<i className="fa fa-expand" aria-hidden="true" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => setCategoriesAccordion((prev) => !prev)}
        >
          <h5>Categor&iacute;as</h5>
        </AccordionSummary>
        <AccordionDetails>
          <ul className="sidebar-categories " data-testid="sidebar-categories">
            <li key="category-all">
              <NavLink
                exact
                to={url}
                onClick={() => setCategoriesAccordion(false)}
              >
                Todas
              </NavLink>
            </li>
            {categories.map((cat) => (
              <li key={`category-${cat.id}`}>
                <NavLink
                  to={`${url}/${cat.code}`}
                  activeClassName="active"
                  onClick={() => setCategoriesAccordion(false)}
                >
                  {cat.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  );

  const renderPriceFilter = () => (
    <div>
      <div className="sidebar-section show-filter-categorys" data-testid="sidebar-price-filter">
        <ul>
          <li>
            <span className="ordenar">Filtrar por precio</span>
          </li>
          <li>
            <span className="ordenar">
              $
              {inputRangeValue[0].toLocaleString('de-DE')}
              {' - $'}
              {inputRangeValue[1].toLocaleString('de-DE')}
            </span>
          </li>
        </ul>
        <Slider
          value={inputRangeValue}
          onChangeCommitted={handleInputRangeChange}
          onChange={handleRangeChange}
          aria-labelledby="range-slider"
          min={0}
          max={maxPrice}
          data-testid="input-range"
        />
      </div>
      <div className="sidebar-section show-filter-categorys-secondary" data-testid="sidebar-price-filter">
        <Accordion className="show-filter-categorys-secondary" expanded={priceAccordion}>
          <AccordionSummary
            expandIcon={<i className="fa fa-expand" aria-hidden="true" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setPriceAccordion((prev) => !prev)}
          >
            <h5>Filtros</h5>
          </AccordionSummary>
          <AccordionDetails>
            <ul className="sidebar-categories-responsive">
              <li key="category-all">
                <ul className="sidebar-title">
                  <li>
                    {' '}
                    <span className="ordenar">Filtrar por precio</span>
                  </li>
                  <li>
                    <span className="ordenar">
                      $
                      {inputRangeValue[0].toLocaleString('de-DE')}
                      {' - $'}
                      {inputRangeValue[1].toLocaleString('de-DE')}
                    </span>
                  </li>
                </ul>
                <Slider
                  value={inputRangeValue}
                  onChange={handleInputRangeChange}
                  aria-labelledby="range-slider"
                  min={0}
                  max={maxPrice}
                  data-testid="input-range"
                />
              </li>
              <br />
              <li>
                <div className="filterOrdenar">
                  <span className="ordenar">Ordenar por:</span>
                  <Select options={orderOptions} onChange={handleSelectSortedProducts} placeholder="Precio o Letra" value={orderProducts} />
                </div>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );

  const renderPagination = () => (
    <div className="row text-center" data-testid="pagination">
      <div className="col">
        <Pagination
          count={total}
          page={page}
          onChange={handlePaginationChange}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  );

  const renderFilter = () => (
    <div className="filters-container" data-testid="pagination">
      <div className="filterOrdenar">
        <span className="ordenar">Ordenar por:</span>
        <Select styles={styles} options={orderOptions} onChange={handleSelectSortedProducts} placeholder="Precio o Letra" value={orderProducts} />
      </div>
    </div>
  );

  const renderItemsWithPagination = () => (
    <>
      {sizeWindow.width > 600 && renderFilter()}
      <br />
      <div
        className="row paginated"
        style={{ margin: -'19px' }}
      >
        {items.map((item) => renderItem(item))}
      </div>
      <br />
      {renderPagination()}
    </>
  );

  const renderNoItems = () => (
    <div className="no-realizo-v2">
      <span data-testid="empty-cart">
        No se encontraron productos
      </span>
    </div>
  );

  const getUrls = () => {
    const urls = [url];
    if (category) {
      const categoryUrl = `${url}/${category.code}`;
      urls.push(categoryUrl);
    }
    return urls;
  };

  const getNames = () => {
    const names = [name];
    if (category) {
      names.push(category.name);
    }
    return names;
  };

  const renderLoading = () => (
    <div className="products-loading">
      <Loading />
    </div>
  );

  const renderMainContent = () => (
    <div className="col clearfix">
      <Breadcrumb
        urls={getUrls()}
        names={getNames()}
      />
      <div className="section">
        <div className="sidebar">
          {renderCategories()}
          {renderPriceFilter()}
        </div>
        <div className="main-content">
          <div data-testid="paginator">
            {items?.length ? renderItemsWithPagination() : renderNoItems()}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div data-testid="products-page">
      <div className="container">
        <div className="row">
          {loading && renderLoading()}
          {!loading && renderMainContent()}
        </div>
      </div>
      <div className="section">
        <div className="offers-products">
          {productsOffers.length > 0 && (
            <>
              <div className="offers-title">
                <h6>¡Ofertas imperdibles!</h6>
              </div>
              <CarrouselProducts products={productsOffers} active={active} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Products.defaultProps = {
  orderProducts: null,
  category: null,
  active: null,
};

Products.propTypes = {
  category: categoryPropTypes,
  categories: categoriesArrayPropTypes.isRequired,
  handleInputRangeChange: PropTypes.func.isRequired,
  handleRangeChange: PropTypes.func.isRequired,
  handlePaginationChange: PropTypes.func.isRequired,
  inputRangeValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  orderOptions: PropTypes.arrayOf(PropTypes.shape).isRequired,
  orderProducts: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  handleSelectSortedProducts: PropTypes.func.isRequired,
  items: productsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  maxPrice: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  renderItem: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  productsOffers: productsArrayPropTypes.isRequired,
  active: PropTypes.string,
};

export default Products;
