import * as constants from 'redux/constants/cart.constants';

export const checkout = (type, formData, items, userId) => ({
  type,
  formData,
  items,
  userId,
});

export const clearCart = () => ({
  type: constants.CART_CLEAR_ITEMS_SUCCEEDED,
});

export const deleteCoupon = () => ({
  type: constants.CART_DELETE_COUPON_REQUESTED,
});

export const deleteNotification = () => ({
  type: constants.CART_DELETE_NOTIFICATION_REQUESTED,
});

export const getProducts = (value) => ({
  type: constants.CART_GET_PRODUCTS_REQUESTED,
  value,
});

export const submitCoupon = (coupon, email, percentageAuto) => ({
  type: constants.CART_SUBMIT_COUPON_REQUESTED,
  coupon,
  email,
  percentageAuto,
});

export const updateCart = (itemId, quantity, descriptionId, message, notification = '') => ({
  type: constants.CART_UPDATE_ITEMS_REQUESTED,
  itemId,
  quantity,
  descriptionId,
  message,
  notification,
});
