import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { cartItemsArrayPropTypes } from 'helpers/propTypes';
import {
  cartItemIdsSelector, cartItemsSelector, cartSizeSelector, cartSubtotalSelector,
} from 'redux/selectors/cart.selector';
import { getProducts } from 'redux/actions/cart.actions';

import Checkout from 'views/Checkout';

const CheckoutContainer = (props) => {
  const {
    cartSize, items, loading, minimumPurchase,
    subtotal, coupon, getProductsAction, ids,
  } = props;
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (ids.length > 0 && !initialized) {
      getProductsAction(ids);
      setInitialized(true);
    }
    return () => getProductsAction([]);
    // eslint-disable-next-line
  }, [getProductsAction, JSON.stringify(ids)]);

  const renderPickUpForm = () => (
    <></>
  );
  return (
    <Checkout
      coupon={coupon}
      cartSize={cartSize}
      items={items}
      loading={loading}
      minimumPurchase={minimumPurchase}
      renderForm={renderPickUpForm}
      shippingText="A confirmar"
      subtotal={subtotal}
    />
  );
};

const mapStateToProps = (state) => ({
  coupon: state.cart.coupon,
  cartItems: state.cart.items,
  ids: cartItemIdsSelector(state),
  cartSize: cartSizeSelector(state),
  items: cartItemsSelector(state),
  loading: state.cart.loading,
  minimumPurchase: state.app.minimumPurchase,
  subtotal: cartSubtotalSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getProductsAction: bindActionCreators(getProducts, dispatch),
});

CheckoutContainer.defaultProps = {
  coupon: null,
};

CheckoutContainer.propTypes = {
  cartSize: PropTypes.number.isRequired,
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
  }),
  getProductsAction: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  items: cartItemsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  subtotal: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutContainer);
