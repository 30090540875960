import { get, post } from 'services/api';

export const login = async (formData) => {
  const response = await post('auth/login', formData);
  return response;
};

export const logout = async () => {
  const response = await get('auth/logout');
  return response;
};

export const profile = async () => {
  const response = await get('users/profile');
  return response;
};

export const register = async (formData) => {
  const response = await post('users/register', formData);
  return response;
};

export const resetPassword = async (formData) => {
  const response = await post('users/resetPassword', formData);
  return response;
};

export const contact = async (formData) => {
  const response = await post('users/contact', formData);
  return response;
};

export const updateProfile = async (formData) => {
  const response = await post('users/update', formData);
  return response;
};

export const changePassword = async (formData) => {
  const response = await post('users/password', formData);
  return response;
};

export const changePasswordEmail = async (formData) => {
  const response = await post('users/passwordEmail', formData);
  return response;
};

export const setVisits = async (data) => {
  const response = await post('auth/visit', data);
  return response;
};

export const setClientDecision = async () => {
  const response = await post('auth/clientDecision');
  return response;
};
export const setVisitsProducts = async (data) => {
  const response = await post('auth/visitProduct', data);
  return response;
};

export const getPurchaseHistory = async () => {
  const response = await get('users/purchaseHistory');
  return response;
};

export const getOrderPDF = async (order_id) => {
  const response = await post('orderPdf', { order_id }, { responseType: 'blob' });
  return response;
};

export const getProvinces = async () => {
  const response = await get('users/provinces');
  return response;
};

export const getPopupImage = async () => {
  const response = await get('/popup');
  return response;
};

export const verifyEmail = async (formData) => {
  console.log(formData);
  const response = await post('users/verify-email', formData);
  return response;
};
