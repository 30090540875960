import { post } from 'services/api';
import { getLocalStorage } from 'helpers/storage';

export const delivery = async (values) => {
  const data = {
    ...values,
    colors: getLocalStorage('descriptionsCart') ?? [],
  };
  const response = await post('checkout/delivery', data);
  return response;
};

export const pickup = async (values) => {
  const data = {
    ...values,
    colors: getLocalStorage('descriptionsCart') ?? [],
  };
  const response = await post('checkout/pickup', data);
  return response;
};

export const submitCoupon = async (coupon, email, percentageAuto) => {
  const response = await post('searchCoupon', { coupon, email, percentageAuto });
  return response;
};
