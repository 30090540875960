import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import * as appConstants from 'redux/constants/app.constants';
import * as constants from 'redux/constants/user.constants';
import {
  login, logout, profile, register, resetPassword, contact, updateProfile,
  changePassword, getPurchaseHistory, getOrderPDF, changePasswordEmail, verifyEmail,
} from 'services/user.services';

export function* userLogin(action) {
  try {
    const data = yield call(login, action.formData);
    yield put({ type: constants.USER_LOGIN_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_LOGIN_FAILED, error });
  }
}

export function* userLogout() {
  try {
    const data = yield call(logout);
    yield put({ type: constants.USER_LOGOUT_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_LOGOUT_FAILED, error });
  }
}

export function* userProfile() {
  try {
    const data = yield call(profile);
    yield put({ type: constants.USER_PROFILE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_PROFILE_FAILED, error });
  }
}

export function* userRegister(action) {
  try {
    const data = yield call(register, action.formData);
    yield put({ type: constants.USER_REGISTER_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_REGISTER_FAILED, error });
  }
}

export function* userResetPassword(action) {
  try {
    const data = yield call(resetPassword, action.formData);
    yield put({ type: constants.USER_RESET_PASSWORD_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_RESET_PASSWORD_FAILED, error });
  }
}

export function* userContact(action) {
  try {
    const data = yield call(contact, action.formData);
    yield put({ type: constants.USER_CONTACT_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_CONTACT_FAILED, error });
  }
}

export function* userUpdateProfile(action) {
  try {
    const data = yield call(updateProfile, action.formData);
    yield put({ type: constants.USER_UPDATE_PROFILE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_UPDATE_PROFILE_FAILED, error });
  }
}

export function* userChangePassword(action) {
  try {
    const data = yield call(changePassword, action.formData);
    yield put({ type: constants.USER_CHANGE_PASSWORD_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_CHANGE_PASSWORD_FAILED, error });
  }
}

export function* userChangePasswordEmail(action) {
  try {
    const data = yield call(changePasswordEmail, action.formData);
    yield put({ type: constants.USER_CHANGE_PASSWORD_EMAIL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_CHANGE_PASSWORD_EMAIL_FAILED, error });
  }
}

export function* userGetPurchaseHistory() {
  try {
    const data = yield call(getPurchaseHistory);
    yield put({ type: constants.USER_GET_PURCHASE_HISTORY_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_GET_PURCHASE_HISTORY_FAILED, error });
  }
}

export function* userGetOrderPDF(action) {
  try {
    const data = yield call(getOrderPDF, action.orderId);
    yield put({ type: constants.USER_GET_ORDER_PDF_SUCCEEDED, data, orderId: action.orderId });
  } catch (error) {
    yield put({ type: constants.USER_GET_ORDER_PDF_FAILED, error });
  }
}

export function* userEmailVerify(action) {
  try {
    console.log(action);
    const data = yield call(verifyEmail, action.formData);
    yield put({ type: constants.USER_VERIFY_EMAIL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_VERIFY_EMAIL_FAILED, error });
  }
}

export function* watchUsers() {
  yield all([
    takeLatest(appConstants.APP_INITIALIZE_REQUESTED, userProfile),
    takeLatest(constants.USER_LOGIN_REQUESTED, userLogin),
    takeLatest(constants.USER_LOGOUT_REQUESTED, userLogout),
    takeLatest(constants.USER_REGISTER_REQUESTED, userRegister),
    takeLatest(constants.USER_RESET_PASSWORD_REQUESTED, userResetPassword),
    takeLatest(constants.USER_CONTACT_REQUESTED, userContact),
    takeLatest(constants.USER_UPDATE_PROFILE_REQUESTED, userUpdateProfile),
    takeLatest(constants.USER_CHANGE_PASSWORD_REQUESTED, userChangePassword),
    takeLatest(constants.USER_CHANGE_PASSWORD_EMAIL_REQUESTED, userChangePasswordEmail),
    takeLatest(constants.USER_GET_PURCHASE_HISTORY_REQUESTED, userGetPurchaseHistory),
    takeLatest(constants.USER_GET_ORDER_PDF_REQUESTED, userGetOrderPDF),
    takeLatest(constants.USER_VERIFY_EMAIL_REQUESTED, userEmailVerify),
  ]);
}
