import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import PropTypes from 'prop-types';

import history from 'helpers/history';
import { productsArrayPropTypes, slidersArrayPropTypes } from 'helpers/propTypes';
import { sectionsArray } from 'views/Home/constants';

// import CarrouselProducts from 'components/commons/CarrouselProducts';
import ItemContainer from 'containers/ItemContainer';
import Loading from 'components/commons/Loading';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Home.scss';

const Home = (props) => {
  const {
    categoriesHome, currentSection, items, loading, newArrivals, onSectionClick, sliders,
  } = props;

  const onCategoryClick = (code) => {
    history.push(`/productos/${code}`);
  };

  const renderSilder = () => (
    <div data-testid="slider">
      <Carousel
        autoPlay
        infiniteLoop
        showStatus={false}
        showThumbs={false}
        swipeable={false}
      >
        {sliders.map(({ id, image, text }) => (
          <div className="main-slider" key={id}>
            <img className="main-slider-img" src={image} alt={text} />
          </div>
        ))}
      </Carousel>
    </div>
  );

  const renderItem = (item) => (
    <div className="col-product" style={{ background: 'rgb(249 249 249)' }} key={`item-${item.id}`}>
      <ItemContainer item={item} />
    </div>
  );

  const renderItems = (productos) => (
    <div className="section">
      <div className="row align-items-center product-slider-container" data-testid="carousel">
        {loading && <Loading />}
        {!loading && (
          <>
            {!productos.length && <h4 className="text-center">No se encontraron productos</h4>}
            {!!productos.length && productos.map((producto) => renderItem(producto))}
          </>
        )}
      </div>
    </div>
  );

  return (
    <div data-testid="home-page">
      {!!sliders.length && renderSilder()}
      <div className="container">
        <div className="row">
          <div className="col">
            {categoriesHome?.length > 0 && (
              <div className="section home-products-carrousel">
                <div className="info-home-title">
                  <h2>Encontrá lo que buscás</h2>
                </div>
                <div className="row align-items-center">
                  <div className="col text-center resp-carrousel">
                    <div className="shop-by-category">
                      <div className="categories">
                        {categoriesHome?.map((item) => (
                          <div className="category" key={item.id} onClick={() => onCategoryClick(item.category.code)} role="presentation">
                            <img alt="" src={item.category.image} />
                            <h4>{item.category.name}</h4>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {newArrivals.length && (
              <div className="section home-products-carrousel">
                <div className="info-home-title">
                  <h2>Nuevos ingresos</h2>
                </div>
                <div className="row align-items-center">
                  <div className="col text-center resp-carrousel">
                    {renderItems(newArrivals)}
                  </div>
                </div>
              </div>
            )}
            <div className="section">
              <div className="row align-items-center">
                <div className="col text-center">
                  <div className="new-arrivals-sorting">
                    <ul className="arrivals-grid-sorting clearfix button-group filters-button-group">
                      {sectionsArray.map((section) => (
                        <li>
                          <button
                            className={`grid-sorting-button ${section.value === currentSection.value ? 'active' : ''}`}
                            data-testid={`section-${section.value}`}
                            key={section.value}
                            onClick={() => onSectionClick(section.value)}
                            type="button"
                          >
                            {section.label}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              {renderItems(items)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Home.propTypes = {
  categoriesHome: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentSection: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  newArrivals: productsArrayPropTypes.isRequired,
  onSectionClick: PropTypes.func.isRequired,
  sliders: slidersArrayPropTypes.isRequired,
};

export default Home;
