import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import * as constants from 'redux/constants/favourite.constants';
import { getIds } from 'services/product.services';

export function* favouriteGetProducts(action) {
  try {
    if (action.value.length > 0) {
      const data = yield call(getIds, action.value);
      yield put({ type: constants.FAVOURITE_GET_PRODUCTS_SUCCEEDED, data });
    }
  } catch (error) {
    yield put({ type: constants.FAVOURITE_GET_PRODUCTS_FAILED, error });
  }
}

export function* watchFavourite() {
  yield all([
    takeLatest(constants.FAVOURITE_GET_PRODUCTS_REQUESTED, favouriteGetProducts),
  ]);
}
