import * as constants from 'redux/constants/product.constants';
import * as appConstants from 'redux/constants/app.constants';

const defaultState = {
  items: [],
  loading: false,
  maxPrice: 0,
  mostSelled: [],
  newArrivals: [],
  offers: [],
  offersItems: [],
  search: [],
  searchItems: [],
  single: null,
};

const productReducer = (state = defaultState, action) => {
  const { data, type } = action;
  switch (type) {
    case appConstants.APP_INITIALIZE_REQUESTED:
    case constants.PRODUCT_GET_ALL_REQUESTED:
    case constants.PRODUCT_GET_ALL_OFFERS_REQUESTED:
    case constants.PRODUCT_GET_ALL_SEARCH_REQUESTED:
    case constants.PRODUCT_GET_MAX_PRICE_REQUESTED:
    case constants.PRODUCT_GET_MOST_SELLED_REQUESTED:
    case constants.PRODUCT_GET_NEW_ARRIVALS_REQUESTED:
    case constants.PRODUCT_GET_OFFERS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.PRODUCT_GET_SEARCH_REQUESTED:
      return {
        ...state,
        search: action.value.trim().length >= 3 ? state.search : [],
      };
    case constants.PRODUCT_GET_SINGLE_REQUESTED:
      return {
        ...state,
        loading: !!action.value,
        single: null,
      };
    case constants.PRODUCT_GET_ALL_SUCCEEDED:
      return {
        ...state,
        items: data.items,
        loading: false,
      };
    case constants.PRODUCT_GET_ALL_OFFERS_SUCCEEDED:
      return {
        ...state,
        offersItems: data.items,
        loading: false,
      };
    case constants.PRODUCT_GET_ALL_SEARCH_SUCCEEDED:
      return {
        ...state,
        searchItems: data.items,
        loading: false,
      };
    case constants.PRODUCT_GET_MAX_PRICE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        maxPrice: data.item,
      };
    case constants.PRODUCT_GET_MOST_SELLED_SUCCEEDED:
      return {
        ...state,
        loading: false,
        mostSelled: data.items,
      };
    case constants.PRODUCT_GET_NEW_ARRIVALS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        newArrivals: data.items,
      };
    case constants.PRODUCT_GET_OFFERS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        offers: data.items,
      };
    case constants.PRODUCT_GET_SEARCH_SUCCEEDED:
      return {
        ...state,
        search: data.items,
      };
    case constants.PRODUCT_GET_SINGLE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        single: data.item,
      };
    case constants.PRODUCT_GET_ALL_FAILED:
    case constants.PRODUCT_GET_ALL_OFFERS_FAILED:
    case constants.PRODUCT_GET_ALL_SEARCH_FAILED:
    case constants.PRODUCT_GET_MAX_PRICE_FAILED:
    case constants.PRODUCT_GET_MOST_SELLED_FAILED:
    case constants.PRODUCT_GET_NEW_ARRIVALS_FAILED:
    case constants.PRODUCT_GET_OFFERS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case constants.PRODUCT_GET_SEARCH_FAILED:
      return {
        ...state,
        search: [],
      };
    case constants.PRODUCT_GET_SINGLE_FAILED:
      return {
        ...state,
        loading: false,
        single: null,
      };
    default:
      return state;
  }
};

export default productReducer;
