import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { whatsappPropTypes } from 'helpers/propTypes';

import { contact } from 'redux/actions/user.actions';

import Contact from 'views/Contact';

const ContactContainer = (props) => {
  const {
    contactAction, loading, minimumPurchase, whatsapp, contacto,
  } = props;
  return (
    <Contact
      loading={loading}
      minimumPurchase={minimumPurchase}
      onSubmit={contactAction}
      whatsapp={whatsapp}
      contact={contacto}
    />
  );
};

const mapStateToProps = (state) => ({
  loading: state.user.loading,
  minimumPurchase: state.app.minimumPurchase,
  whatsapp: state.app.whatsapp,
  contacto: state.app.contact,
});

const mapDispatchToProps = (dispatch) => ({
  contactAction: bindActionCreators(contact, dispatch),
});

ContactContainer.defaultProps = {
  whatsapp: null,
  contacto: null,
};

ContactContainer.propTypes = {
  contactAction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  whatsapp: whatsappPropTypes,
  contacto: PropTypes.shape({
    number: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactContainer);
