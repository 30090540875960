import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import closeButton from './assets/closeButton.svg';
import cartIcon from './assets/cartIcon.svg';
import purchaseIcon from './assets/purchase.svg';

import './Modal.scss';

const Modal = (props) => {
  const {
    children, isLarge, isCart, loading, onClose, title, isPurchase, isForgotPassword, isImage,
  } = props;

  const closeModal = () => {
    if (!loading) {
      onClose();
    }
  };

  const handleKeyUp = (/* event */) => {
    // 27: Escape key
    /* if (event.keyCode === 27) {
      closeModal();
    } */
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    window.addEventListener('keyup', handleKeyUp, false);
    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('keyup', handleKeyUp, false);
    };
  });

  const renderImageCloseButton = () => (
    <div className={isImage ? 'image-close' : ''}>
      <button
        type="button"
        className={isImage ? 'button-close-popup' : 'close'}
        data-dismiss="modal"
        onClick={closeModal}
        data-testid="close-modal-button"
      >
        &times;
      </button>
    </div>
  );

  const renderCloseButton = () => (
    <>
      {title ? (
        <div
          className={isCart || isPurchase ? ('modal-cart') : ('modal-title')}
          role="presentation"
        >
          {
            isCart || isPurchase ? (
              <div className="titleModalContainer">
                <img src={isCart ? cartIcon : purchaseIcon} alt="" />
                {' '}
                <span className="titleCartModal">{title}</span>
              </div>
            ) : (
              <div className={!isForgotPassword ? 'titleDiv' : ''}>
                <h4 className={!isForgotPassword ? 'registerTitle' : 'passwordTitle'}>{title}</h4>
              </div>
            )
          }

          <div className="close-button">
            <img src={closeButton} alt="close" onClick={closeModal} role="presentation" />
          </div>
        </div>
      ) : (
        <div className="close-button">
          <img src={closeButton} alt="close" onClick={closeModal} role="presentation" />
        </div>
      )}
    </>
  );

  return (
    <div className="modal" role="dialog" data-testid="modal">
      <div className="modal-overlay" role="presentation" onClick={closeModal} />
      <div className="vertical-alignment-helper">
        <div className="vertical-align-center">
          <div className={isCart || isPurchase ? ('modal-carrito') : (`modal-dialog ${isLarge ? 'modal-lg' : ''}`)} data-testid="modal-dialog">
            <div className={isImage ? 'modal-content-image' : 'modal-content'}>
              <div className="container">
                {!loading && (!isImage ? renderCloseButton() : renderImageCloseButton())}
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.defaultProps = {
  isLarge: false,
  loading: false,
  isCart: false,
  isPurchase: false,
  title: null,
  isForgotPassword: false,
  isImage: false,
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isLarge: PropTypes.bool,
  isCart: PropTypes.bool,
  isPurchase: PropTypes.bool,
  isForgotPassword: PropTypes.bool,
  isImage: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
