import React from 'react';
import PropTypes from 'prop-types';

import {
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    border: 0,
    color: theme.palette.common.grey,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const CustomTable = (props) => {
  const {
    renderRow, rows, size, titles,
  } = props;

  return (
    <TableContainer data-testid="custom-table-component" sx={{ boxShadow: 0 }}>
      <Table aria-label="table" size={size}>
        <TableHead sx={{
          color: 'rgba(96, 96, 96)',
          backgroundColor: 'pink',
        }}
        >
          <TableRow>
            {titles.map((title, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <StyledTableCell align="center" key={`${title}-${index}`}><span className="title-purchase-history">{title}</span></StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(renderRow)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CustomTable.defaultProps = {
  size: 'medium',
};

CustomTable.propTypes = {
  renderRow: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  size: PropTypes.string,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CustomTable;
