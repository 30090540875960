import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import * as appConstants from 'redux/constants/app.constants';
import * as constants from 'redux/constants/product.constants';
import * as cartConstants from 'redux/constants/cart.constants';
import {
  getAll, getMaxPrice, getMostSelled, getNewArrivals, getOffers, getSearch,
  getSingle, getAllOffers, getAllSearch,
} from 'services/product.services';

export function* productGetAll() {
  try {
    const data = yield call(getAll);
    yield put({ type: constants.PRODUCT_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.PRODUCT_GET_ALL_FAILED, error });
  }
}

export function* productGetAllOffers() {
  try {
    const data = yield call(getAllOffers);
    yield put({ type: constants.PRODUCT_GET_ALL_OFFERS_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.PRODUCT_GET_ALL_OFFERS_FAILED, error });
  }
}

export function* productGetAllSearch(action) {
  try {
    const { search } = action;
    const data = yield call(getAllSearch, search);
    yield put({ type: constants.PRODUCT_GET_ALL_SEARCH_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.PRODUCT_GET_ALL_SEARCH_FAILED, error });
  }
}

export function* productGetMaxPrice() {
  try {
    const data = yield call(getMaxPrice);
    yield put({ type: constants.PRODUCT_GET_MAX_PRICE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.PRODUCT_GET_MAX_PRICE_FAILED, error });
  }
}

export function* productGetMostSelled() {
  try {
    const data = yield call(getMostSelled);
    yield put({ type: constants.PRODUCT_GET_MOST_SELLED_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.PRODUCT_GET_MOST_SELLED_FAILED, error });
  }
}

export function* productGetNewArrivals() {
  try {
    const data = yield call(getNewArrivals);
    yield put({ type: constants.PRODUCT_GET_NEW_ARRIVALS_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.PRODUCT_GET_NEW_ARRIVALS_FAILED, error });
  }
}

export function* productGetOffers() {
  try {
    const data = yield call(getOffers);
    yield put({ type: constants.PRODUCT_GET_OFFERS_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.PRODUCT_GET_OFFERS_FAILED, error });
  }
}

export function* productGetSearch(action) {
  try {
    if (action.value.trim().length >= 3) {
      const data = yield call(getSearch, action.value);
      yield put({ type: constants.PRODUCT_GET_SEARCH_SUCCEEDED, data });
    }
  } catch (error) {
    yield put({ type: constants.PRODUCT_GET_SEARCH_FAILED, error });
  }
}

export function* productGetSingle(action) {
  try {
    if (action.value) {
      const data = yield call(getSingle, action.value);
      yield put({ type: constants.PRODUCT_GET_SINGLE_SUCCEEDED, data });
    }
  } catch (error) {
    yield put({ type: constants.PRODUCT_GET_SINGLE_FAILED, error });
  }
}

export function* watchProducts() {
  yield all([
    takeLatest(
      [
        appConstants.APP_INITIALIZE_REQUESTED,
        cartConstants.CART_CHECKOUT_SUCCEEDED,
      ],
      productGetAll,
    ),
    takeLatest(
      [
        appConstants.APP_INITIALIZE_REQUESTED,
        cartConstants.CART_CHECKOUT_SUCCEEDED,
      ],
      productGetAllOffers,
    ),
    takeLatest(constants.PRODUCT_GET_ALL_SEARCH_REQUESTED, productGetAllSearch),
    takeLatest(appConstants.APP_INITIALIZE_REQUESTED, productGetMaxPrice),
    takeLatest(
      [
        appConstants.APP_HOME_REQUESTED,
        cartConstants.CART_CHECKOUT_SUCCEEDED,
      ],
      productGetMostSelled,
    ),
    takeLatest(
      [
        appConstants.APP_HOME_REQUESTED,
        cartConstants.CART_CHECKOUT_SUCCEEDED,
      ],
      productGetNewArrivals,
    ),
    takeLatest(
      [
        appConstants.APP_HOME_REQUESTED,
        cartConstants.CART_CHECKOUT_SUCCEEDED,
      ],
      productGetOffers,
    ),
    takeLatest(constants.PRODUCT_GET_SEARCH_REQUESTED, productGetSearch),
    takeLatest(constants.PRODUCT_GET_SINGLE_REQUESTED, productGetSingle),
  ]);
}
