import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Breadcrumb from 'components/commons/Breadcrumb';

import { productsArrayPropTypes } from 'helpers/propTypes';
import { getProducts } from 'redux/actions/favourite.actions';
import { favouriteItemsSelector } from 'redux/selectors/favourite.selector';
import Loading from 'components/commons/Loading';
import ItemContainer from './ItemContainer';

const FavouriteContainer = (props) => {
  const {
    getProductsAction, ids, items, loading,
  } = props;
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (ids.length > 0 && !initialized) {
      getProductsAction(ids);
      setInitialized(true);
    }
    return () => getProductsAction([]);
    // eslint-disable-next-line
  }, [getProductsAction, JSON.stringify(ids)]);

  const renderItem = (item) => (
    <div className="col-product" style={{ background: 'rgb(249 249 249)' }} key={`item-${item.id}`}>
      <ItemContainer item={item} />
    </div>
  );

  return (
    <div className="container">
      <div className="row">
        <div className="col clearfix">
          <Breadcrumb urls={['/favoritos']} names={['Productos favoritos']} />
          <div className="row align-items-center product-slider-container" data-testid="carousel">
            {loading && <Loading />}
            {!loading && (
              items.length > 0 ? items.map((item) => renderItem(item)) : (
                <div className="no-realizo carrito-empty">
                  <span data-testid="empty-cart">
                    No se encontraron productos
                  </span>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ids: state.favourite.items,
  items: favouriteItemsSelector(state),
  loading: state.favourite.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getProductsAction: bindActionCreators(getProducts, dispatch),
});

FavouriteContainer.propTypes = {
  getProductsAction: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  items: productsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(FavouriteContainer);
