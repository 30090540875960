import React from 'react';
import PropTypes from 'prop-types';

import QuantitySelector from '../QuantitySelector/QuantitySelector';

import './DescriptionItem.scss';

const DescriptionItem = (props) => {
  const { description, onQuantityChange, storage } = props;
  const quantity = storage ? storage.quantity : 0;
  return (
    <>
      <>
        {
        description.stock_descriptions.rgb ? (
          <div className="input-color">
            {/* <strong className="">{description.stock_descriptions.name}</strong> */}
            <span className="color" style={{ backgroundColor: description.stock_descriptions.rgb }} />
          </div>
        ) : (
          <div className="input-name">
            <span className="name">{description.stock_descriptions.name}</span>
          </div>
        )
        }
        <QuantitySelector
          limit={description.stock}
          onQuantityChange={onQuantityChange}
          quantity={quantity}
          description={description}
        />
      </>
    </>
  );
};

DescriptionItem.defaultProps = {
  storage: null,
};

DescriptionItem.propTypes = {
  description: PropTypes.shape().isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  storage: PropTypes.shape(),
};

export default DescriptionItem;
