import { reset } from 'redux-form';

import * as userConstants from 'redux/constants/user.constants';

const resetForm = (store, formName) => {
  const { dispatch } = store;
  dispatch(reset(formName));
};

const formMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case userConstants.USER_CONTACT_SUCCEEDED:
      resetForm(store, 'contact');
      break;
    case userConstants.USER_CHANGE_PASSWORD_SUCCEEDED:
      resetForm(store, 'password');
      break;
    default:
      break;
  }
  return next(action);
};

export default formMiddleware;
