export const CART_CHECKOUT_PICKUP_REQUESTED = 'CART_CHECKOUT_PICKUP_REQUESTED';
export const CART_CHECKOUT_DELIVERY_REQUESTED = 'CART_CHECKOUT_DELIVERY_REQUESTED';
export const CART_CHECKOUT_SUCCEEDED = 'CART_CHECKOUT_SUCCEEDED';
export const CART_CHECKOUT_FAILED = 'CART_CHECKOUT_FAILED';

export const CART_CLEAR_ITEMS_REQUESTED = 'CART_CLEAR_ITEMS_REQUESTED';
export const CART_CLEAR_ITEMS_SUCCEEDED = 'CART_CLEAR_ITEMS_SUCCEEDED';
export const CART_CLEAR_ITEMS_FAILED = 'CART_CLEAR_ITEMS_FAILED';

export const CART_DELETE_COUPON_REQUESTED = 'CART_DELETE_COUPON_REQUESTED';
export const CART_DELETE_NOTIFICATION_REQUESTED = 'CART_DELETE_NOTIFICATION_REQUESTED';

export const CART_GET_PRODUCTS_REQUESTED = 'CART_GET_PRODUCTS_REQUESTED';
export const CART_GET_PRODUCTS_SUCCEEDED = 'CART_GET_PRODUCTS_SUCCEEDED';
export const CART_GET_PRODUCTS_FAILED = 'CART_GET_PRODUCTS_FAILED';

export const CART_SUBMIT_COUPON_REQUESTED = 'CART_SUBMIT_COUPON_REQUESTED';
export const CART_SUBMIT_COUPON_SUCCEEDED = 'CART_SUBMIT_COUPON_SUCCEEDED';
export const CART_SUBMIT_COUPON_FAILED = 'CART_SUBMIT_COUPON_FAILED';

export const CART_UPDATE_ITEMS_REQUESTED = 'CART_UPDATE_ITEMS_REQUESTED';
export const CART_UPDATE_ITEMS_SUCCEEDED = 'CART_UPDATE_ITEMS_SUCCEEDED';
export const CART_UPDATE_ITEMS_FAILED = 'CART_UPDATE_ITEMS_FAILED';
